import React from 'react';
import "antd/dist/antd.css";
import Amplify from "aws-amplify";
import awsconfig from "../../aws-exports";
import Header from '../Header';
import Slide from '../Slide';

Amplify.configure(awsconfig);

function FolderDetail() {
  return (
    <div>
      <div className="header">
        <Header />
      </div>
      <main>
        <Slide />
      </main>
    </div>
  );
}

export default FolderDetail;
export const ADMIN = ['sunloft.co.jp', 'soc.shukutoku.ac.jp'];
export const STUDENT = ['stu.shukutoku.ac.jp', 'rikkeisoft.com'];
export const ADMIN_NEW = [
                'taeko.watanabe@u.shukutoku.ac.jp',
                'masana.ujihara@u.shukutoku.ac.jp',
                'akane.suzuki@u.shukutoku.ac.jp',
                'akiko.manaka@u.shukutoku.ac.jp',
                'shunsuke.namura@u.shukutoku.ac.jp',
                'tomoya.kawase@u.shukutoku.ac.jp',
                'masumi.watanabe@u.shukutoku.ac.jp',
                'y-nakamura@sunloft.co.jp',
                'duonglh@rikkeisoft.com',
                'androidapp@ext.sunloft.co.jp'
            ];
export const STUDENT_NEW = ['u.shukutoku.ac.jp', 'rikkeisoft.com'];
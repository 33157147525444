import React, {useRef} from 'react';
import { Card } from "antd";
import TextArea from "antd/es/input/TextArea";
import PropTypes from "prop-types";

function FreeText(props) {
  const { handleGetFreeText = value => {}, freeText, isTextareaEditable, windowDimension } = props
  const textareaRef = useRef(null)

  const onFieldChange = (e) => {
    handleGetFreeText(e.target.value)
  }

  return (
    <Card className="card-container card-freetext" style={{height: (windowDimension.height - 182)/ 2 - 46}}>
      <TextArea rows={10}
                value={freeText}
                onChange={e => onFieldChange(e)}
                ref={textareaRef}
                disabled={isTextareaEditable}
      />
    </Card>
  );
}

FreeText.propTypes = {
  handleGetFreeText: PropTypes.func,
  freeText: PropTypes.string,
  autoFocus: PropTypes.bool,
  isTextareaEditable: PropTypes.bool,
  windowDimension: PropTypes.object,
}

export default FreeText;
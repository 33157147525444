import React from "react";
import {Modal, Form, Input, message, Button, Icon} from "antd";
import {Helper} from "../../utils/Helper";
import { BOX_TYPE } from "../../const/graph-value";

const FormItem = Form.Item;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3 }
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 18}
  }
};

let errorMsg = '';
let descErrorMsg = '';
const loadingIcon = <Icon type='loading' />

const CreateShape = Form.create()(
  class extends React.Component {
    state = {
      isLoading: false,
      shape: {
        title: "",
        desc: "",
        type: this.props.currentTask || '',
      },
      error: false,
      isBtnSaveDisabled: true,
      isDescTouched: false,
    };

    descLabel = this.state.shape.type === BOX_TYPE.box_care? '問題': 'メモ';
    requireDesc = this.state.shape.type === BOX_TYPE.box_care? true: false;

    componentDidMount() {
      message.config({
        top: 100,
        duration: 1.5
      });
    }

    onFieldChange = (field, e) => {
      const { shape, isDescTouched } = this.state;
      const newShape = { ...shape };
      let isError = false;

      if (field === 'shapeTitle') {
        newShape['title'] = Helper.trimString(e.target.value)
      } else if (field === 'shapeDesc') {
        this.setState({ isDescTouched: true });
        newShape['desc'] = Helper.trimString(e.target.value)
      }
      const { title, desc } = newShape;
      if (title.length === 0) {
        isError = true;
        errorMsg = '概要を入力してください。';
      } else {
        isError = false;
        errorMsg = ''
      }
      if (isDescTouched) {
        if (desc.length === 0 && this.requireDesc) {
          isError = true;
          descErrorMsg = '入力してください。';
        } else {
          descErrorMsg = '';
        }
      } else if (!isDescTouched && this.requireDesc) {
        isError = true;
      }
      this.setState({
        shape: newShape,
        error: isError,
        isBtnSaveDisabled: isError,
      });
    }
    
    hideModal = () => {
      this.props.handleCancel();
      this.setState({ isBtnSaveDisabled: true });
    };
    
    handleConfirm = () => {
      const { shape } = this.state
      const id_shape = this.props.maxOrder;
      const { title: shapeTitle, desc: shapeDesc, type } = shape;
      const isAddOrder = type === BOX_TYPE.box_care? true: false;
      const shapeTitleDisplay = isAddOrder ? `#${id_shape}. ${shapeTitle}` : shapeTitle;
      const shapeDescDisplay = isAddOrder ? `#${id_shape}. ${shapeDesc}` : shapeDesc;
      this.setState({
        isLoading: true,
        isBtnSaveDisabled: true,

      });

      setTimeout(() => {
        this.setState({
          isLoading: false
        });
        this.props.handleConfirm({
          type,
          shapeTitle,
          shapeDesc,
          shapeTitleDisplay,
          shapeDescDisplay,
          id_shape
        });
      }, 1000);
    };
    
    handleDescChange(e) {
      this.setState({
        shapeDesc: e.target.value
      });
    }
    
    render() {
      const { shape, error, isLoading } = this.state;

      return (
        <div>
          <Modal
            title="問題の作成"
            visible={this.props.visible}
            onCancel={this.hideModal}
            okText="保存"
            cancelText="キャンセル"
            width="620px"
            className="modal-slide"
            footer={[
              <Button key="1" onClick={this.hideModal}>キャンセル</Button>,
              <Button type="primary" key="2" onClick={this.handleConfirm} disabled={this.state.isBtnSaveDisabled}>
                {isLoading ? loadingIcon : ""} 保存
              </Button>
            ]}
          >
            <Form>
              <FormItem
                label="概要"
                required
                layout="horizontal"
                {...formItemLayout}
                className={errorMsg ? 'has-error' : ''}
              >
                <TextArea 
                  value={shape.title}
                  rows={4}
                  onChange={e => this.onFieldChange('shapeTitle', e)}
                  name="shapeTitle"
                  id='create'
                />
                {error && errorMsg && <p className='ant-form-explain'>{errorMsg}</p>}
              </FormItem>
              <FormItem
                label={this.descLabel}
                required={this.requireDesc}
                layout="horizontal"
                {...formItemLayout}
                className={descErrorMsg ? 'has-error' : ''}
              >
                <TextArea
                  rows={4}
                  value={shape && shape.desc}
                  onChange={e => this.onFieldChange('shapeDesc', e)}
                  name="shapeDesc"
                />
                {error && descErrorMsg && <p className='ant-form-explain'>{descErrorMsg}</p>}
              </FormItem>
            </Form>
          </Modal>
        </div>
      );
    }
  }
);

export default CreateShape;

// User Store
import { createSlice } from "@reduxjs/toolkit";
import { ADMIN_NEW } from "../config/emailDomain";

const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {}

const initialUser = {
  token: user.token || '',
  email: user.email || '',
  nickname: user.nickname || '',
  role: user.role || '',
  username: user.username || ''
};

// Slice
const slice = createSlice({
  name: "user",
  initialState: {
    user: initialUser,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
      localStorage.setItem(
        "token",
        action.payload.token
      );
    },
    logoutSuccess: (state) => {
      state.user = initialUser;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
  },
});

export default slice.reducer;

// Actions
const { loginSuccess, logoutSuccess } = slice.actions;

export const setUserInfo = (user) => (dispatch) => {
  const userData = {
    ...user.attributes,
    token: user.signInUserSession.accessToken.jwtToken,
    role: setUserRole(user.attributes.email),
    username: user.username
  }
  return dispatch(loginSuccess(userData));
};

export const logOut = (dispatch) => {
  return dispatch(logoutSuccess());
}

const setUserRole = (email) => {
  // const emailAddress = email.split("@").pop();
  if (ADMIN_NEW.includes(email)) {
    return "admin";
  } else {
    return "student";
  }
};

import React, { useState, useEffect } from "react";
import { FolderFilled } from "@ant-design/icons";
import { API_URL } from "../../config/api.js";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import {
  Table,
  Input,
  Row,
  Icon,
  Button,
  Pagination,
  Empty,
  Tooltip,
} from "antd";

import EditFolder from "../../components/EditFolder";
import CreateFolder from "../../components/CreateFolder";
import DeleteFolder from "../../components/DeleteFolder";

const { Search } = Input;

function Folder() {
  const { user } = useSelector((state) => state.user);
  const columns = [
    {
      title: "フォルダ名",
      dataIndex: "name",
      sorter: true,
      key: "name",
      render: (name, record) => (
        <div>
          <FolderFilled style={{ fontSize: "16px", color: "#2F80ED" }} />{" "}
          <a onClick={() => handleRedirectDetail(record)}>{name}</a>
        </div>
      ),
    },
    {
      title: "記入者",
      dataIndex: "created_by_username",
      sorter: true,
      key: "created_by_username",
    },
    {
      title: "登録日時",
      dataIndex: "created_at",
      // defaultSortOrder: "descend",
      sorter: true,
      key: "created_at",
    },
    {
      title: "操作",
      dataIndex: "",
      key: "x",
      render: (text, record) => (
        <div>
          <Tooltip title="Edit">
            <Button
              type="primary"
              onClick={() => handleRedirectEdit(record)}
              disabled={disabledEdit(record)}
            >
              <Icon type="edit" />
            </Button>
          </Tooltip>
          &nbsp;
          <Tooltip title="Delete">
            <Button
              type="danger"
              onClick={() => handleDelete(record)}
              disabled={disabledEdit(record)}
            >
              <Icon type="delete" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const history = useHistory();
  const [data, setData] = useState();
  const [createVisible, setCreateVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [currentFolder, setCurrentFolder] = useState();
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState({ field: "email", order: "descend" });
  const [paginate, setPaginate] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (pagination, filters, sorter, extra) => {
    if (sorter) {
      fetchData(
        searchText,
        paginate.current,
        paginate.pageSize,
        sorter.field,
        sorter.order
      );
      setSort({ field: sorter.field, order: sorter.order });
    } else {
      fetchData(
        searchText,
        paginate.current,
        paginate.pageSize,
        sort.field,
        sort.order
      );
    }
  };

  const handlePaginationChange = (current, pageSize) => {
    fetchData(searchText, current, pageSize, sort.field, sort.order);
  };

  const fetchData = (search, page, limit, sort, order) => {
    setIsLoading(true);
    var query = `?search=${search}&sort=${sort}&order=${order}&page=${page}&limit=${limit}`;
    const url = `${API_URL}/folders${query}`;
    fetch(url).then((response) => {
      response.json().then((data) => {
        const results = data.result.folders.map((row) => ({
          key: row._id,
          name: row.name,
          created_by: row.created_by,
          created_by_username: row.created_by_username,
          created_at: row.created_at,
        }));
        setData(results);
        if (page && limit) {
          setPaginate({ current: page, pageSize: limit, total: data.total });
        }
      });
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchData("", 1, 10, "email", "descend");
  }, []);

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    paginate.current = 1;
    fetchData(
      searchText,
      paginate.current,
      paginate.pageSize,
      sort.field,
      sort.order
    );
  };

  const handleCancel = () => {
    setCreateVisible(false);
  };

  const handleCancelEdit = () => {
    setEditVisible(false);
  };

  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <a>前</a>;
    }
    if (type === "next") {
      return <a>次へ</a>;
    }
    return originalElement;
  };

  const handleCreate = () => {
    setCreateVisible(true);
  };

  const handleCancelDelete = () => {
    setDeleteVisible(false);
  };

  const handleConfirm = () => {
    setCreateVisible(false);
    setEditVisible(false);
    handleSearch("");
  };

  const handleConfirmDelete = () => {
    setDeleteVisible(false);
    if (
      paginate.total % 10 === 1 &&
      paginate.current === Math.floor(paginate.total / 10) + 1
    ) {
      fetchData(
        searchText,
        paginate.current - 1,
        paginate.pageSize,
        sort.field,
        sort.order
      );
    } else {
      fetchData(
        searchText,
        paginate.current,
        paginate.pageSize,
        sort.field,
        sort.order
      );
    }
  };

  const handleRedirectEdit = (record) => {
    setCurrentFolder(record);
    setEditVisible(true);
  };

  const handleRedirectDetail = (record) => {
    history.push(`/folders/detail/${record.key}`);
  };

  const handleDelete = (record) => {
    setCurrentFolder(record);
    setDeleteVisible(true);
  };

  const disabledEdit = (record) => {
    if (record && record.root_folder) { 
      return true;
    }
    if (user.role !== 'admin') {
      return true;
    }
    return false;
  }

  useEffect(() => {
    var body = document.getElementsByTagName("body")[0];
    body.setAttribute("class", "");
  }, []);

  return (
    <div className="slide-content">
      <Row className="welcome">
        <p className="welcome-content">ようこそ {user.nickname}!</p>
      </Row>
      <Row className="slide-search">
        <Button
          type="primary"
          className="add-slide-button"
          onClick={handleCreate}
          disabled={disabledEdit()}
        >
          <Icon type="folder-add" style={{ fontSize: "18px" }} />{" "}
          フォルダ新規登録
        </Button>
        <CreateFolder
          visible={createVisible}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
        />
        <EditFolder
          currentFolder={currentFolder}
          visible={editVisible}
          handleCancelEdit={handleCancelEdit}
          handleConfirm={handleConfirm}
        />

        <DeleteFolder
          currentFolder={currentFolder}
          visible={deleteVisible}
          handleCancelDelete={handleCancelDelete}
          handleConfirmDelete={handleConfirmDelete}
        />
        <Tooltip title="Search">
          <Search
            placeholder=" フォルダ名で検索"
            onSearch={(value) => handleSearch(value)}
            className="search-button"
          />
        </Tooltip>
      </Row>
      <Row className="slide-table">
        <Table
          columns={columns}
          dataSource={data}
          onChange={handleChange}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description=" フォルダはまだありません。"
              />
            ),
          }}
          loading={isLoading}
          showSorterTooltip={true}
        />
      </Row>
      <Row className="slide-paginate">
      </Row>
    </div>
  );
}

export default Folder;

import React from 'react';
import { Spin } from 'antd';
import './Loading.scss';

const Loading = ({loading = false}) => {
    if(!loading) return null
    return (
        <div className='loading-overlay-1'>
            <Spin tip="読み込み中..." size="large" className='loading-spin'/>
        </div>
    )
}

export default Loading
export const translations = {
  'Username cannot be empty': 'ユーザー名を入力してください。',
  'Password cannot be empty': 'パスワードを入力してください。',
  'Incorrect username or password.': 'ユーザー名またはパスワードが間違っています',
  'User does not exist.': 'ユーザー名またはパスワードが間違っています。',
  'Email cannot be empty': 'メールを入力してください。',
  'Confirm Password cannot be empty': 'パスワードの確認を入力してください。',
  'Password confirmation cannot be empty': 'パスワードの確認を入力してください。',
  'The password confirmation does not match.': 'パスワードの確認は統一されていません。',
  'Username is not valid.': 'ユーザー名は文字または数字だけで入力してください。',
  'Email is not valid.': 'メールは正しく入力してください。',
  'Confirmation code cannot be empty': '検証コードを入力してください。',
  'Invalid verification code provided, please try again.': '検証コードは間違っています。もう一度試してください。',
  'User already exists': 'ユーザー名は既に存在しています',
  'Username/client id combination not found.': '該当のユーザーが存在しません。',
  'Password must be between 6 to 20 characters.': 'パスワードは正しく入力してください。',
  'Network error': 'Network error',
  'Custom auth lambda trigger is not configured for the user pool.': 'パスワードを入力してください。',
  'The password does not match.': 'パスワードは統一されていません。',
  'User is disabled.': 'ユーザー名またはパスワードが間違っています。',
};
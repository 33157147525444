import { Form, Input, Button, Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import "./ConfirmPassword.css";
import Amplify from "aws-amplify";
import awsconfig from "../../aws-exports";
import { useHistory } from "react-router-dom";
import { translations } from "../../lang/translations";
import { Redirect } from "react-router-dom";

Amplify.configure(awsconfig);
let isBtnSubmitDisabled = false;

function ConfirmPassword(props) {
  const history = useHistory();
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [information, setInformation] = useState('');
  useEffect(() => {
    function getUser() {
      const user = props.location.user;
      const password = props.location.password;
      setUser(user);
      setPassword(password);
    }

    getUser();
  }, [props.location.user, props.location.password]);
  const [errors, setErrors] = useState({});
  const handleChange = event => {
    const { name, value } = event.target;
    setInformation({
      ...information,
      [name]: value
    });
  }

 const validate = values => {
    let err = {};
    if (!values.old_password) {
      err.old_password = 'Password cannot be empty';
    } else if (values.old_password.length < 6 || values.old_password.length > 20) {
      err.old_password = 'Password must be between 6 to 20 characters.';
    } else if (password !== values.old_password ) {
      err.old_password = 'The password does not match.';
    }

    if (!values.new_password) {
      err.new_password = 'Password cannot be empty';
    } else if (values.new_password.length < 6 || values.new_password.length > 20) {
      err.new_password = 'Password must be between 6 to 20 characters.';
    }

    if (values.repassword !== values.new_password ) {
      err.repassword = 'The password confirmation does not match.';
    }
    setErrors(err);
    return err;
  }
  const handleSubmit = async (event) => {
    isBtnSubmitDisabled = true;
    event.preventDefault();
    var errs = validate(information);
    if (Object.keys(errs).length === 0) {
      const new_password = information.new_password;
      user.completeNewPasswordChallenge(new_password, user.challengeParam.requiredAttributes, {
        onSuccess: function (session) {
          localStorage.setItem('token', user.signInUserSession.accessToken.jwtToken);
          localStorage.setItem('username', user.username);
          if (localStorage.getItem('token')) {
            history.push('/');
          }
        },
        onFailure: function (err) {
          isBtnSubmitDisabled = false;
          console.log('completeNewPassword failure', err);
        }
      });
    } else {
      isBtnSubmitDisabled = false;
    }
  }

  const { from } = { from: { pathname: "/login" } };
  return (
    props.location.user ?
    <div>
      <header>
        <h1 className="logo"><span className="text-slide">情報整理シート・関連図</span></h1>
      </header>
      <main className="container">
        <div className="block-content">
          <h2 className="title">パスワード変更</h2>
          <Row className="content">
            <Col span={14} offset={5}>
              <Form className="reset-password-form" onSubmit={handleSubmit}>
              <Form.Item>
              <label>パスワード <span style={{ color: 'rgba(194, 0, 0, 1)' }}>(*)</span></label>
                  <Input
                    type="password"
                    placeholder="パスワード"
                    name="old_password"
                    onChange={handleChange}
                  />
                </Form.Item>
                <div className={errors.old_password ? 'error-text' : ''}><span style={{ color: 'rgba(194, 0, 0, 1)' }}>{errors.old_password ? translations[errors.old_password] : ''}</span></div>
                <Form.Item >
                <label>新しいパスワード <span style={{ color: 'rgba(194, 0, 0, 1)' }}>(*)</span></label>
                  <Input
                    type="password"
                    placeholder="新しいパスワード"
                    name="new_password"
                    onChange={handleChange}
                  />
                </Form.Item>
                <div className={errors.new_password ? 'error-text' : ''}><span style={{ color: 'rgba(194, 0, 0, 1)' }}>{errors.new_password ? translations[errors.new_password] : ''}</span></div>
                <Form.Item>
                  <label>パスワードの確認 <span style={{ color: 'rgba(194, 0, 0, 1)' }}>(*)</span></label>
                  <Input
                    type="password"
                    placeholder="パスワードの確認"
                    name="repassword"
                    onChange={handleChange}
                  />
                </Form.Item>
                <div className={errors.repassword ? 'error-text' : ''}><span style={{ color: 'rgba(194, 0, 0, 1)' }}>{errors.repassword ? translations[errors.repassword] : ''}</span></div>
                <Form.Item>
                  <Row>
                    <Col span={10} offset={7}>
                      <Button type="primary" htmlType="submit" className="button-form" disabled={isBtnSubmitDisabled}>
                        送信
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </main>
    </div>
    : <Redirect to={from} />
  );
}

export default ConfirmPassword;

import React, { useEffect} from 'react';
import { Row, Icon } from 'antd';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { setUserInfo, logOut } from '../../store/user';

import "antd/dist/antd.css";
import { Auth } from "aws-amplify";
import './Header.css'

function Header() {
  const dispatch = useDispatch()
  const history = useHistory();
  const { user } = useSelector(state => state.user);
  useEffect(() => {
    async function getUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        dispatch(setUserInfo(user));
      } catch (error) {
        localStorage.setItem('token', '');
        localStorage.setItem('user', '');
        history.push('/login');
      }
    }
    getUser();
  }, [])

  const signOut = async (e) => {
    e.preventDefault();
    try {
      await Auth.signOut();
      dispatch(logOut());
      history.push('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  const handleBackHome = () => {
    history.push('/');
  }
  return (
    <Row className="slide-header" type='flex' justify='space-between' align='middle'>
      <h1 className="homepage" onClick={handleBackHome}><span className="text-slide">情報整理シート・関連図</span></h1>
      <div className="header-info">
          <span className="username">{ user.nickname }</span>
          <a href="/#" onClick={signOut}><Icon type="export" /> ログアウト</a>
      </div>
    </Row>
  );
}

export default Header;
import React, { useEffect, useState } from 'react';
import {Card, List} from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function Guess(props) {
  const { windowDimension, guess } = props;
  const [selected, setSelected] = useState("");
  const [listMondai, setListMondai] = useState(guess);

  useEffect(() => {
    setListMondai(guess);
  }, [guess])

  const handleClick = (id) => {
    props.handleChooseShape(id);
    setSelected(id);
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newList = reorder(
      listMondai,
      result.source.index,
      result.destination.index
    );

    setListMondai(newList);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    result.forEach((element, index) => {
      const elementDesc = element.desc.split('#').pop().split('.')[0];
      const newIndex = index + 1;
      if (Number(elementDesc) !== newIndex) {
        element.desc = element.desc.replace(/#.*\./, `#${newIndex}.`);
        element.id_shape = newIndex;
        props.handleUpdateShape(element, newIndex);
      }
    });
  
    return result;
  };

  return (
    <Card className="card-container card-guess" style={{height: (windowDimension.height - 182)/ 2 - 46}}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {listMondai.length > 0 && listMondai.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <List.Item key={item.id}>
                        <List.Item.Meta
                          style={{border: selected === item.id && props.selected !== 0 ? '2px solid #E85757' : ''}}
                          title={<p>{item.desc}</p>}
                          onClick={() => handleClick(item.id)}
                        />
                      </List.Item>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Card>
  );
}

export default Guess;
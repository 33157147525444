import React, {Component, createRef} from "react";
import PropTypes from 'prop-types'
import { Col, Row, Card, Modal, Button} from "antd";
import {
  mxGraph,
  mxConstants,
  mxEdgeStyle,
  mxGraphHandler,
  mxGuide,
  mxEdgeHandler,
  mxRubberband,
  mxDragSource,
  mxClient,
  mxConnectionHandler,
  mxUtils,
  mxEvent,
  mxImage,
  mxConstraintHandler,
  mxObjectCodec,
  mxConnectionConstraint,
  mxCellState,
  mxPoint,
  mxPerimeter,
  mxCellHighlight,
  mxKeyHandler,
  mxVertexHandler,
  mxPanningHandler,
  mxLog
} from "mxgraph-js";
import { BgColorsOutlined, FullscreenOutlined, FullscreenExitOutlined, PrinterOutlined , UndoOutlined } from '@ant-design/icons';
import CreateShape from "../CreateShape";
import EditShape from "../EditShape";
import {Helper} from "../../utils/Helper";
import iconZoomIn from '../../assets/zoomin.svg'
import iconZoomOut from '../../assets/zoomout.svg'
import iconShape from '../../assets/rectangle.svg'
import iconArrow from '../../assets/arrow-right.svg'
import iconUndo from '../../assets/undo.svg'
import iconPoint from '../../assets/point1.png'
import { BOX_COLORS, BOX_TYPE, LINE_WEIGHTS, DOT_WEIGHTS } from "../../const/graph-value";
import EditArrow from "../EditArrow";
import FullScreen from '../FullScreen/FullScreen'
import ControlButton from "../CanvasControlButton/ControlButton";
import ZoomButton from "../ZoomButton/ZoomButton";
import iconBringToFront from '../../assets/bring-to-front.png';
import iconSendToBack from '../../assets/send-to-back.png';
import Loading from "../Loading/Loading";
import { domToPng } from 'modern-screenshot';
const {confirm} = Modal;
const zoomFactor = 1.2;

class mxCellAttributeChange {
  // constructor
  constructor(cell, attribute, value) {
    this.cell = cell;
    this.attribute = attribute;
    this.value = value;
    this.previous = value;
  }
  // Method
  execute() {
    if (this.cell != null) {
      var tmp = this.cell.getAttribute(this.attribute);

      if (this.previous == null) {
        this.cell.value.removeAttribute(this.attribute);
      } else {
        this.cell.setAttribute(this.attribute, this.previous);
      }

      this.previous = tmp;
    }
  }
}
class JsonCodec extends mxObjectCodec {
  constructor() {
    super(value => { });
  }
  encode(value) {
    const xmlDoc = mxUtils.createXmlDocument();
    const newObject = xmlDoc.createElement("ShapeObject");
    for (let prop in value) {
      newObject.setAttribute(prop, value[prop]);
    }
    return newObject;
  }
  decode(model) {
    return Object.keys(model.cells)
      .map(iCell => {
        const currentCell = model.getCell(iCell);
        return currentCell.value !== undefined ? currentCell : null;
      })
      .filter(item => item !== null);
  }
}

class MxGraphGridAreaEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graph: {},
      dragElt: null,
      createVisible: false,
      currentNode: null,
      currentTask: "",
      editVisible: false,
      currentShape: {},
      currentHighlight: '',
      highlightList: [],
      initScale: 1,
      containerDimension: {
        width: '100vw',
        height: '100vh'
      },
      negativeShape: {
        negativeX: 0,
        negativeY: 0
      },
      negativeShapeHidden: null,
      showColorPicker: false,
      selectingBoxCell: false,
      editArrowVisible: false,
      histories: [],
      undoManager: null,
      enableUndo: false,
      showLineWidthPicker: false,
      showDotLinePicker: false,
      selectingArrow: false,
      isOpenPrintPreview: false,
      slidePos: { x: 0, y: 0 },
      slideScale: 0,
      preventSlideMove:{
        left: false,
        right: false,
        up: false,
        down: false
      },
      loading: false
    };
    this.slidePreviewImage = null;
    this.graphContainerRef = createRef()
    this.mxSidebarBoxCauseRef = createRef()
    this.mxSidebarBoxCareRef = createRef()
    this.toolbarRef = createRef()
    this.mxConnectorRef = createRef()
    this.LoadGraph = this.LoadGraph.bind(this);
    this.colorButtonRef = createRef();
    this.lineWeightButtonRef = createRef();
    this.dotLineButtonRef = createRef();
    this.handleColorButtonClickOutside = this.handleColorButtonClickOutside.bind(this);
  }

  componentDidMount() {
    this.LoadGraph();
    document.addEventListener('mousedown', this.handleColorButtonClickOutside);
    setTimeout(() => {
      this.saveHistory();
    }, 500);
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
  }

  handleKeyDown = (e) => {
    if(e.key === 'Shift'){
      this.shiftKeyPressed = true
    }else{
      this.shiftKeyPressed = false
    }
  }
  handleKeyUp = () => {
    this.shiftKeyPressed = false
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleColorButtonClickOutside);
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keydown', this.handleKeyUp);
  }

  /**
   * Alert if clicked on outside of color button
   */

  

  handleColorButtonClickOutside(event) {
    if (!this.colorButtonRef?.current?.contains(event.target)) {
      setTimeout(() => {
        this.setState({ showColorPicker: false });
      }, 200);
    }
    if(!this.lineWeightButtonRef?.current?.contains(event.target)){
      setTimeout(() => {
        this.setState({ showLineWidthPicker: false });
      }, 200);
    }
    if(!this.dotLineButtonRef?.current?.contains(event.target)){
      setTimeout(() => {
        this.setState({ showDotLinePicker: false });
      }, 200);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { graph, currentHighlight, highlightList } = this.state

    this.setState({
      containerDimension: {
        width: graph && graph.container && graph.container.scrollWidth,
        height: graph && graph.container && graph.container.scrollHeight
      }
    })

    if (this.props.guess) {
      this.props.guess.map((item) => {
        if (!highlightList[item.id]) {
          // let highlight = new mxCellHighlight(graph, '#000', 1);
          let graphModal = graph.getModel();
          let target = graphModal.getCell(item.id);

          let tStyle = target.getStyle();
          if (!tStyle) {
            tStyle = '';
          }
          const arrStyle = tStyle.split(';');
          let strokeWidth = 1;
          let dashed = 0;
          for (var i = 0; i < arrStyle.length; i++) {
            if (arrStyle[i]) {
              let itemStyle = arrStyle[i].split('=');
              if (itemStyle[0] == 'dashed' && itemStyle[1]) {
                dashed = parseInt(itemStyle[1]);
              }
              if (itemStyle[0] == 'strokeWidth' && itemStyle[1]) {
                strokeWidth = parseInt(itemStyle[1]);
              }
            }
          }
          const highlight = new mxCellHighlight(graph, '#000', strokeWidth, dashed);
          highlight.spacing = strokeWidth * 2;
          highlight.highlight(graph.view.getState(target));
          highlightList[item.id] = highlight;
          this.setState({
            highlightList: highlightList
          });
        }
      })
    }

    if(currentHighlight !== '') {
      const highlightCell = currentHighlight.state?.cell;
      if (!highlightCell) return;
      const { edges } = highlightCell;
      if (edges && edges.length > 0) {
        graph.setCellStyles(mxConstants.STYLE_STROKECOLOR, '#555555', edges);
        graph.setCellStyles('strokeWidth', 1, edges);
      }
      currentHighlight.destroy();
    }
    if (this.props.cellId !== undefined && this.props.cellId !== nextProps) {
      let highlight = new mxCellHighlight(graph, 'red', 3);
      let graphModal = graph.getModel();
      let target = graphModal.getCell(this.props.cellId);
      highlight.highlight(graph.view.getState(target));
      const { edges } = target;
      if (edges && edges.length > 0) {
        graph.setCellStyles(mxConstants.STYLE_STROKECOLOR, 'red', edges);
        graph.setCellStyles('strokeWidth', 2, edges);
      }
      this.setState({
        currentHighlight: highlight
      });
    }
  }

  LoadGraph(data) {
    var graphContainer = this.graphContainerRef.current;
    // Checks if the browser is supported
    if (!mxClient.isBrowserSupported()) {
      // Displays an error message if the browser is not supported.
      mxUtils.error("Browser is not supported!", 200, false);
    } else {
      var graph = new mxGraph(graphContainer);
      this.setState(
        {
          graph: graph,
        },
        () => {
          this.loadGlobalSetting();
          this.setGraphSetting();
          this.settingConnection();
          this.renderShape();
          this.initToolbar();
          if (this.props.isGraphEditable) {
            this.createDragBoxCauseElement();
            this.createDragBoxCareElement();
            this.createDragConnectorElement();
          }

          // Adds cells to the model in a single step
          graph.getModel().beginUpdate();
          try {
          } finally {
            // Updates the display
            graph.getModel().endUpdate();
            graph.model.addListener(mxEvent.CHANGE, (sender, evt) => {
              const changes = evt.getProperty('edit').changes;
              for (let i = 0; i < changes.length; i++)
              {
                const change = changes[i];
                // Manage any mxChildChange
                if (change.constructor.name === "mxChildChange") {
                  // connect change
                  if ((change.index === undefined) && (change.child.edge)) {
                    this.saveHistory();
                  }
                }
              }
            });
          }
        }
      );
      // Disables the built-in context menu
      mxEvent.disableContextMenu(graphContainer);
      // Trigger event after selection
      graph
        .getSelectionModel()
        .addListener(mxEvent.CHANGE, this.selectionChange);
      graph.addListener(mxEvent.RESIZE_CELLS, () => this.saveHistory());
      graph.addListener(mxEvent.MOVE_CELLS, () => this.saveHistory());
      graph.getDefaultParent();
    }
  }
  
  loadGlobalSetting = () => {
    // Enable alignment lines to help locate
    mxGraphHandler.prototype.guidesEnabled = true;
    // Alt disables guides
    mxGuide.prototype.isEnabledForEvent = function (evt) {
      return !mxEvent.isAltDown(evt);
    };
    // Specifies if waypoints should snap to the routing centers of terminals
    mxEdgeHandler.prototype.snapToTerminals = true;
    mxConstraintHandler.prototype.pointImage = new mxImage(
      iconPoint,
      15,
      15
    );
    mxConstraintHandler.prototype.highlightColor = '#45FE00'
  };

  setGraphSetting = () => {
    const { graph } = this.state;
    const that = this;
    graph.gridSize = 30;
    graph.setPanning(true);
    graph.setTooltips(false);
    graph.setConnectable(true);
    graph.setCellsEditable(false);
    graph.setEnabled(this.props.isGraphEditable);
    // Enables HTML labels
    graph.setHtmlLabels(true);
    graph.centerZoom = true;
    // Autosize labels on insert where autosize=1
    graph.resizeContainer = true
    graph.autoSizeCellsOnAdd = true;
    graph.allowAutoPanning = true;
    graph.sizeDidChange()

    const keyHandler = new mxKeyHandler(graph);
    keyHandler.bindKey(8, () => {
      const currentNode = graph.getSelectionCell();
      if (graph.isEnabled() && currentNode) {
        const confirmTitle = (currentNode.vertex)
            ? 'ボックスを削除してもよろしいですか'
            : '矢印を削除してもよろしいですか';
        confirm({
          title: confirmTitle,
          okText: 'OK',
          okType: 'danger',
          cancelText: 'キャンセル',
          onOk() {
            const idShape = currentNode.vertex ? currentNode.value.getAttribute('id_shape') : '';
            graph.removeCells([currentNode]);
            if (idShape !== '') {
              const guessDelete = {id_shape: idShape};
              const newGuess = [...that.props.guess];
              const guessIndex = newGuess.findIndex(({id_shape}) => Number(id_shape) === Number(guessDelete.id_shape));
              if (guessIndex !== -1) {
                  newGuess.splice(guessIndex, 1);
              }
              that.props.getArrayGuess(newGuess);
            }
            that.renderJsonData();
            that.setState({
              editArrowVisible: false,
            })
          },
        });
      }
    });

    new mxRubberband(graph);
    var style = [];
    style[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RECTANGLE;
    style[mxConstants.STYLE_PERIMETER] = mxPerimeter.RectanglePerimeter;
    style[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_MIDDLE;
    style[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_CENTER;
    style[mxConstants.STYLE_FONTCOLOR] = "#555555";
    style[mxConstants.STYLE_FILLCOLOR] = "#ffffff";
    style[mxConstants.STYLE_DASHED] = 0;
    style[mxConstants.STYLE_STROKEWIDTH] = "1";
    style[mxConstants.STYLE_STROKECOLOR] = "#000000";
    style[mxConstants.HANDLE_FILLCOLOR] = "#80c6ee";
    graph.getStylesheet().putDefaultVertexStyle(style);
    style = [];
    style[mxConstants.STYLE_STROKECOLOR] = "#555555";
    style[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_CONNECTOR;
    style[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_CENTER;
    style[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_MIDDLE;
    style[mxConstants.STYLE_EDGE] = mxEdgeStyle.ElbowConnector;
    style[mxConstants.STYLE_ENDARROW] = mxConstants.ARROW_CLASSIC;
    style[mxConstants.STYLE_FONTSIZE] = "8";
    style[mxConstants.VALID_COLOR] = "#27bf81";
    graph.getStylesheet().putDefaultEdgeStyle(style);
    graph.addListener(mxEvent.DOUBLE_CLICK, function(sender, evt) {
      var cell = evt.getProperty('cell');
      if (cell && cell.vertex === true) {
        that.createPopupMenu(graph, evt, cell)
        evt.consume();
      } else if (cell && (cell.value === "Edge" || cell.value === "EdgeD")) {
        that.openArrowEdit(graph, evt, cell)
        evt.consume();
      } else if (cell && (cell.value === "EdgeV" || cell.value === "EdgeH")) {
        // graph.cellsMovable = false
      }
    })

    // One finger pans (no rubberband selection) must start regardless of mouse button
    mxPanningHandler.prototype.isPanningTrigger = function(me)
    {
      var evt = me.getEvent();
      return (me.getState() == null && !mxEvent.isMouseEvent(evt)) ||
        (mxEvent.isPopupTrigger(evt) && (me.getState() == null ||
        mxEvent.isControlDown(evt) || mxEvent.isShiftDown(evt)));
    };

    // Don't clear selection if multiple cells selected
    var graphHandlerMouseDown = mxGraphHandler.prototype.mouseDown;
    mxGraphHandler.prototype.mouseDown = function(sender, me)
    {
      graphHandlerMouseDown.apply(this, arguments);

      if (this.graph.isCellSelected(me.getCell()) && this.graph.getSelectionCount() > 1)
      {
        this.delayedSelection = false;
      }
    };

    // On connect the target is selected and we clone the cell of the preview edge for insert
    mxConnectionHandler.prototype.selectCells = function(edge, target)
    {
      if (target != null)
      {
        this.graph.setSelectionCell(target);
      }
      else
      {
        this.graph.setSelectionCell(edge);
      }
    };

    // Adds connect icon to selected vertex
    var connectorSrc = iconArrow;
    var vertexHandlerInit = mxVertexHandler.prototype.init;
    mxVertexHandler.prototype.init = function()
    {
      vertexHandlerInit.apply(this, arguments);
      // Only show connector image on one cell and do not show on containers
      if (this.graph.connectionHandler.isEnabled() &&
        this.graph.isCellConnectable(this.state.cell) &&
        this.graph.getSelectionCount() == 1 && mxClient.IS_TOUCH)
      {
        this.connectorImg = mxUtils.createImage(connectorSrc);
        this.connectorImg.style.cursor = 'pointer';
        this.connectorImg.style.width = '20px';
        this.connectorImg.style.height = '20px';
        this.connectorImg.style.position = 'absolute';
        
        if (!mxClient.IS_TOUCH)
        {
          mxEvent.redirectMouseEvents(this.connectorImg, this.graph, this.state);
        }

        // Starts connecting on touch/mouse down
        mxEvent.addGestureListeners(this.connectorImg,
          mxUtils.bind(this, function(evt)
          {
            this.graph.popupMenuHandler.hideMenu();
            this.graph.stopEditing(false);
            
            var pt = mxUtils.convertPoint(this.graph.container,
                mxEvent.getClientX(evt), mxEvent.getClientY(evt));
            this.graph.connectionHandler.start(this.state, pt.x, pt.y);
            this.graph.isMouseDown = true;
            this.graph.isMouseTrigger = mxEvent.isMouseEvent(evt);
            mxEvent.consume(evt);
          })
        );

        this.graph.container.appendChild(this.connectorImg);
      }

      this.redrawHandles();
    };

    var vertexHandlerReset = mxVertexHandler.prototype.reset;
    mxVertexHandler.prototype.reset = function()
    {
      vertexHandlerReset.apply(this, arguments);
      
      if (this.connectorImg != null)
      {
        this.connectorImg.style.visibility = '';
      }
    };

    var vertexHandlerRedrawHandles = mxVertexHandler.prototype.redrawHandles;
    mxVertexHandler.prototype.redrawHandles = function()
    {
      vertexHandlerRedrawHandles.apply(this);

      if (this.state != null && this.connectorImg != null)
      {
        var pt = new mxPoint();
        var s = this.state;
        // Top right for single-sizer
        if (mxVertexHandler.prototype.singleSizer)
        {
          pt.x = s.x + s.width - this.connectorImg.offsetWidth / 2;
          pt.y = s.y - this.connectorImg.offsetHeight / 2;
        }
        else
        {
          pt.x = s.x + s.width + mxConstants.HANDLE_SIZE / 2 + 4 + this.connectorImg.offsetWidth / 2;
          pt.y = s.y + s.height / 2;
        }
        
        var alpha = mxUtils.toRadians(mxUtils.getValue(s.style, mxConstants.STYLE_ROTATION, 0));
        
        if (alpha != 0)
        {
          var cos = Math.cos(alpha);
          var sin = Math.sin(alpha);
          
          var ct = new mxPoint(s.getCenterX(), s.getCenterY());
          pt = mxUtils.getRotatedPoint(pt, cos, sin, ct);
        }
        
        this.connectorImg.style.left = (pt.x - this.connectorImg.offsetWidth / 2) + 'px';
        this.connectorImg.style.top = (pt.y - this.connectorImg.offsetHeight / 2) + 'px';
      }
    };

    var vertexHandlerDestroy = mxVertexHandler.prototype.destroy;
    mxVertexHandler.prototype.destroy = function(sender, me)
    {
      vertexHandlerDestroy.apply(this, arguments);
      if (this.connectorImg != null)
      {
        this.connectorImg.parentNode.removeChild(this.connectorImg);
        this.connectorImg = null;
      }
    };

    // Pre-fetches touch connector
    new Image().src = connectorSrc;

    mxUtils.getScrollOrigin = function(node, includeAncestors, includeDocument) {
      var result = new mxPoint();
      return result;
    };
    
    var body = document.getElementsByTagName('body')[0]
    body.setAttribute('class', 'body-detail')

    graph.convertValueToString = function (cell) {
      if (
        mxUtils.isNode(cell.value) &&
        cell.value.nodeName.toLowerCase() === "shapeobject"
      ) {
        // Returns a DOM for the label
        var div = document.createElement("div");
        div.setAttribute("class", "shapeWrapper");
        div.innerHTML = `<h2 class='shapeTitle'>${cell.getAttribute("text_display", "")}</h2>`;

        return div;
      }
      return "";
	  };
    graph.addMouseListener({
      mouseDown: function(sender, evt)
      {
        mxLog.debug('mouseDown');
      },
      mouseMove: function(sender, evt)
      {
        mxLog.debug('mouseMove');
      },
      mouseUp: function(sender, evt)
      {
        mxLog.debug('mouseUp');
        return that.mouseUpFunction(sender, evt);
      }
    });
  };

  initToolbar = () => {
    const { graph } = this.state;
    var toolbar = this.toolbarRef.current;
    const that = this
    var button1 = document.createElement('button');
    button1.innerHTML = `<img class="icon" src=${iconZoomIn} /> ズームイン`;

    mxEvent.addListener(button1, 'click', function(evt) {
      graph.resizeContainer = true;
      evt.preventDefault();
      if (that.state.initScale !== null && that.state.initScale < 1) {
        that.setState({
          initScale: that.state.initScale * zoomFactor
        }, () => {
          graph.zoomTo(that.state.initScale);

          //scroll to cell to visible
          that.autoScrollCellToVisiable();
        })
      } else {
        return;
      }
    });
    toolbar.appendChild(button1);

    var button2 = document.createElement('button');
    button2.innerHTML = `<img class="icon" src=${iconZoomOut} /> ズームアウト`;

    mxEvent.addListener(button2, 'click', function(evt) {
      graph.resizeContainer = true
      evt.preventDefault();
      if (that.state.initScale !== null && (that.state.initScale > 0.4 && that.state.initScale <= 1)) {
        that.setState({
          initScale: that.state.initScale / zoomFactor
        }, () => {
          graph.zoomTo(that.state.initScale);
        })
      } else {
        return;
      }
    });
    toolbar.appendChild(button2);
  };

  settingConnection = () => {
    const { graph } = this.state;
    mxConstraintHandler.prototype.intersects = function (
      icon,
      point,
      source,
      existingEdge
    ) {
      return !source || existingEdge || mxUtils.intersects(icon.bounds, point);
    };

    var mxConnectionHandlerUpdateEdgeState =
    mxConnectionHandler.prototype.updateEdgeState;
    // mxConnectionHandler.prototype.connectImage = new mxImage(iconArrow, 20, 20);
    mxConnectionHandler.prototype.updateEdgeState = function (pt, constraint) {
      if (pt != null && this.previous != null) {
        var constraints = this.graph.getAllConnectionConstraints(this.previous);
        var nearestConstraint = null;
        var dist = null;

        for (var i = 0; i < constraints.length; i++) {
          var cp = this.graph.getConnectionPoint(this.previous, constraints[i]);

          if (cp != null) {
            var tmp =
              (cp.x - pt.x) * (cp.x - pt.x) + (cp.y - pt.y) * (cp.y - pt.y);

            if (dist == null || tmp < dist) {
              nearestConstraint = constraints[i];
              dist = tmp;
            }
          }
        }

        if (nearestConstraint != null) {
          this.sourceConstraint = nearestConstraint;
        }

        // In case the edge style must be changed during the preview:
        // this.edgeState.style['edgeStyle'] = 'orthogonalEdgeStyle';
        // And to use the new edge style in the new edge inserted into the graph,
        // update the cell style as follows:
        //this.edgeState.cell.style = mxUtils.setStyle(this.edgeState.cell.style, 'edgeStyle', this.edgeState.style['edgeStyle']);
      }

      mxConnectionHandlerUpdateEdgeState.apply(this, arguments);
    };

    if (graph.connectionHandler.connectImage == null) {
      graph.connectionHandler.isConnectableCell = function (cell) {
        return false;
      };
      mxEdgeHandler.prototype.isConnectableCell = function (cell) {
        return graph.connectionHandler.isConnectableCell(cell);
      };
    }

    graph.getAllConnectionConstraints = function (terminal) {
      if (terminal != null && this.model.isVertex(terminal.cell)) {
        return [
          new mxConnectionConstraint(new mxPoint(0.5, 0), true),
          new mxConnectionConstraint(new mxPoint(0, 0.5), true),
          new mxConnectionConstraint(new mxPoint(1, 0.5), true),
          new mxConnectionConstraint(new mxPoint(0.5, 1), true)
        ];
      }
      return null;
    };

    // Connect preview
    graph.connectionHandler.createEdgeState = function (me) {
      var edge = graph.createEdge(
        null,
        null,
        "Edge",
        null,
        null,
        "edgeStyle=orthogonalEdgeStyle"
      );

      return new mxCellState(
        this.graph.view,
        edge,
        this.graph.getCellStyle(edge)
      );
    };
  };

  createDragBoxCauseElement = () => {
    const { graph } = this.state;
    const tasksDrag = this.mxSidebarBoxCauseRef.current
    const value = tasksDrag.getAttribute("data-value");
    let ds = mxUtils.makeDraggable(
      tasksDrag,
      this.graphF,
      (graph, evt, target, x, y) =>
        this.funct(graph, evt, target, x, y, value),
      this.dragElt,
      null,
      null,
      graph.autoscroll,
      true
    );
    ds.isGuidesEnabled = function () {
      return graph.graphHandler.guidesEnabled;
    };
    ds.createDragBoxCauseElement = mxDragSource.prototype.createDragElement;
  };

  createDragBoxCareElement = () => {
    const { graph } = this.state;
    const tasksDrag = this.mxSidebarBoxCareRef.current
    const value = tasksDrag.getAttribute("data-value");
    let ds = mxUtils.makeDraggable(
      tasksDrag,
      this.graphF,
      (graph, evt, target, x, y) =>
        this.funct(graph, evt, target, x, y, value),
      this.dragElt,
      null,
      null,
      graph.autoscroll,
      true
    );
    ds.isGuidesEnabled = function () {
      return graph.graphHandler.guidesEnabled;
    };
    ds.createDragBoxCareElement = mxDragSource.prototype.createDragElement;
  };

  createDragConnectorElement = () => {
    const { graph } = this.state;
    const tasksDrag = this.mxConnectorRef.current
    const value = tasksDrag.getAttribute("data-value");
    let ds = mxUtils.makeDraggable(
      tasksDrag,
      this.graphF,
      (graph, evt, target, x, y) =>
      this.functArrow(graph, evt, target, x, y, value),
      null,
      null,
      null,
      graph.autoscroll,
      true
    );
    ds.isGuidesEnabled = function () {
      return graph.graphHandler.guidesEnabled;
    };
    ds.createDragConnectorElement = mxDragSource.prototype.createDragElement;
  };

  renderShape = () => {
    const { graph } = this.state;
    const { workingSlide } = this.props
    this.setState(
      {
        json: workingSlide
      },
      () => {
        this.renderJSON(JSON.parse(workingSlide), graph);
      }
    );
  }

  autoScrollCellToVisiable = () => {
    const { graph } = this.state;
    var defaultObject = this.getObjectDefault('default');
    var graphModel = graph.getModel();
    const parent = graph.getDefaultParent();
      //remove negative shape hidden to resize scroll bar for container
      if (this.state.negativeShapeHidden) {
        graph.removeCells([this.state.negativeShapeHidden]);
      }

      //get x of cell
      var valueListX = Object.values(graphModel.cells).map(function(cell){
        return cell.getGeometry() ? cell.getGeometry().x : 0;
      });

      //get x of cell
      var valueListY = Object.values(graphModel.cells).map(function(cell){
        return cell.getGeometry() ? cell.getGeometry().y : 0;
      });

      //get minX and min Y
      var minX = valueListX.reduce(function(a, b) { return Math.min(a, b); });
      var minY = valueListY.reduce(function(a, b) { return Math.min(a, b); });

      //create cell visible for scroll to cell
      minX = minX < 0 ? minX - 15 : minX;
      minY = minY < 0 ? minY - 15 : minY;

    var cellVisible = graph.insertVertex(parent, null, defaultObject, minX, minY, 0, 0);

      this.setState({
        negativeShapeHidden: cellVisible
      });
      //scroll to cell to visible
      graph.scrollCellToVisible(cellVisible);
  }

  renderJSON = (dataModel, graph) => {
    const { windowDimension } = this.props
    const jsonEncoder = new JsonCodec();
    let vertices = {};
    const parent = graph.getDefaultParent();
    graph.getModel().beginUpdate(); // Adds cells to the model in a single step
    try {
      if (dataModel.graph.length === 0) {
        this.renderVerticalEdge(graph, parent, (windowDimension.width) / 2, (windowDimension.height));
        this.renderHorizontalEdge(graph, parent, windowDimension.width, (windowDimension.height)/ 2);
      } else {
        const negativeXShape =
          dataModel && dataModel.graph.reduce((res, node) => {
            return (node.geometry.x < res.geometry.x) ? node : res
          })
        const negativeYShape =
          dataModel && dataModel.graph.reduce((res, node) => {
            return (node.geometry.y < res.geometry.y) ? node : res
          })

        this.setState({
          negativeShape: {
            negativeX: negativeXShape.geometry.x,
            negativeY: negativeYShape.geometry.y
          }
        }, () => {
          dataModel && dataModel.graph.map(node => {
            if (node.value) {
              if (typeof node.value === "object") {
                const xmlNode = jsonEncoder.encode(node.value);
                vertices[node.id] = graph.insertVertex(
                  parent,
                  null,
                  xmlNode,
                  node.geometry.x - this.state.negativeShape.negativeX,
                  node.geometry.y - this.state.negativeShape.negativeY,
                  node.geometry.width,
                  node.geometry.height,
                  node.style
                );
              } else if (node.value === "Edge" || node.value === "EdgeD" || node.value === "EdgeV" || node.value === "EdgeH") {
                graph.insertEdge(
                  parent,
                  null,
                  node.value,
                  vertices[node.source],
                  vertices[node.target],
                  node.style
                );
              }
            }
            return node;
          });
        })
      }
    } finally {
      graph.getModel().endUpdate(); // Updates the display
    }
  };

  getJsonModel = graph => {
    const encoder = new JsonCodec();
    const jsonModel = encoder.decode(graph.getModel());
    return {
      graph: jsonModel
    };
  };

  renderVerticalEdge = (graph, parent, x, y) => {
    var defaultObject = this.getObjectDefault('default');
    var v3 = graph.insertVertex(parent, null, defaultObject, x, 0, 0, 0);
    var v4 = graph.insertVertex(parent, null, defaultObject, x, y, 0, 0);

    // Edge from top to bottom
    var e2 = graph.insertEdge(parent, null, 'EdgeV', v3, v4,
    'strokeColor=#2F80ED;strokeWidth=1;endArrow=none;startArrow=none;endSize=8;edgeStyle=orthogonalEdgeStyle;dashed=1');
    // Sets the horizontal edge position
    e2.geometry.points = [new mxPoint(x, y)];
  };

  renderHorizontalEdge = (graph, parent, x, y) => {
    var defaultObject = this.getObjectDefault('default');
    var v1 = graph.insertVertex(parent, null, defaultObject,  0, y, 0, 0);
    var v2 = graph.insertVertex(parent, null, defaultObject, x, y, 0, 0);
    // Edge from right to left
    var e1 = graph.insertEdge(parent, null, 'EdgeH', v1, v2,
    'strokeColor=#2F80ED;strokeWidth=1;endArrow=none;startArrow=none;endSize=8;edgeStyle=elbowEdgeStyle;dashed=1');
    // Sets the vertical edge position
    e1.geometry.points = [new mxPoint(x, y)];
  };

  stringifyWithoutCircular = json => {
    return JSON.stringify(
      json,
      (key, value) => {
        if (
          (key === "parent" || key === "source" || key === "target") &&
          value !== null
        ) {
          return value.id;
        } else if (key === "value" && value !== null && value.localName) {
          let results = {};
          Object.keys(value.attributes).forEach(attrKey => {
            const attribute = value.attributes[attrKey];
            results[attribute.nodeName] = attribute.nodeValue;
          });
          return results;
        }
        return value;
      },
      4
    );
  };

  handleCancel = () => {
    const { histories } = this.state;
    histories.pop();
    this.setState({ createVisible: false, histories });
    this.state.graph.removeCells([this.state.currentNode]);
  };

  getBoxExtraSpace = ({ width, height }) => {
    return ({ width: width + 6, height: height + 6 })
  }

  handleConfirm = fields => {
    const { graph, highlightList } = this.state;
    const cell = graph.getSelectionCell();
    const textWidthHeight = Helper.getHeightOfText(fields.shapeTitleDisplay)
    const formattedWidth = textWidthHeight.width;
    const formattedHeight = textWidthHeight.height;
    const space = this.getBoxExtraSpace({width: formattedWidth, height: formattedHeight });

    if (cell)  {
      const currentCell = cell.getGeometry()
      currentCell.width =  + space.width;
      currentCell.height = + space.height;
      cell.setGeometry(currentCell);
    }

    this.applyHandler(graph, cell, "text", fields.shapeTitle);
    this.applyHandler(graph, cell, "text_display", fields.shapeTitleDisplay);
    this.applyHandler(graph, cell, "desc", fields.shapeDesc);
    this.applyHandler(graph, cell, "desc_display", fields.shapeDescDisplay);
    this.applyHandler(graph, cell, "id_shape", fields.shapeDescDisplay ? fields.id_shape : '');
    this.setState({ createVisible: false });

    if (fields.type === BOX_TYPE.box_care) {
      const highlight = new mxCellHighlight(graph, '#000', 1);
      highlight.highlight(graph.view.getState(cell));
      highlightList[cell.id] = highlight;
      this.setState({
        highlightList: highlightList
      });
      const objectGuess = {id: cell.id, id_shape: fields.id_shape, desc: fields.shapeDescDisplay};
      this.props.getArrayGuess([...this.props.guess, objectGuess]);
      this.props.getMaxOrder(fields.id_shape + 1);
      this.setState({ highlightList });
    }
    this.renderJsonData();
  };

  applyHandler = (graph, cell, name, newValue) => {
    graph.getModel().beginUpdate();
    try {
      const edit = new mxCellAttributeChange(cell, name, newValue);
      graph.getModel().execute(edit);
    } finally {
      graph.getModel().endUpdate();
    }
  };

  handleCancelEdit = () => {
	this.setState({ editVisible: false });
  }

  handleCancelArrowEdit = () => {
	  this.setState({ editArrowVisible: false });
  }

  handleSaveShape = (fields) => {
    this.saveHistory();
    const { graph, highlightList } = this.state;
    const cell = graph.getSelectionCell();
    const textWidthHeight = Helper.getHeightOfText(fields.shapeTitleDisplay)
    const formattedWidth = textWidthHeight.width;
    const formattedHeight = textWidthHeight.height;
    const space = this.getBoxExtraSpace({width: formattedWidth, height: formattedHeight });
    const currentCell = cell.getGeometry()

    currentCell.width = space.width;
    currentCell.height = space.height;
    cell.setGeometry(currentCell);
    if (fields.type === BOX_TYPE.box_cause) {
      const guessDelete = {id_shape: fields.id_shape};
      const newGuess = [...this.props.guess];
      const guessIndex = newGuess.findIndex(({id_shape}) => id_shape === guessDelete.id_shape);
      if (guessIndex !== -1) {
        newGuess.splice(guessIndex, 1);
      }
      this.props.getArrayGuess(newGuess);
      this.applyHandler(graph, cell, "text", fields.shapeTitle);
      this.applyHandler(graph, cell, "text_display", fields.shapeTitle);
      this.applyHandler(graph, cell, "desc", fields.shapeDesc);
      this.applyHandler(graph, cell, "desc_display", fields.shapeDesc);
      this.applyHandler(graph, cell, "id_shape", '');
      if (highlightList[cell.id]) {
        var destroyHighlight = highlightList[cell.id];
        destroyHighlight.destroy();
        highlightList.splice(cell.id, 1);
        this.setState({
          highlightList: highlightList
        });
      }
    } else {
      if (!highlightList[cell.id]) {
        const highlight = new mxCellHighlight(graph, '#000', 1);
        highlight.highlight(graph.view.getState(cell));
        highlightList[cell.id] = highlight;
        this.setState({
          highlightList: highlightList
        });
      }
      this.applyHandler(graph, cell, "text", fields.shapeTitle);
      this.applyHandler(graph, cell, "text_display", fields.shapeTitleDisplay);
      this.applyHandler(graph, cell, "desc", fields.shapeDesc);
      this.applyHandler(graph, cell, "desc_display", fields.shapeDescDisplay);
      this.applyHandler(graph, cell, "id_shape", fields.shapeDescDisplay ? fields.id_shape : '');
      const guessUpdate = {id: Number(cell.id), id_shape: fields.id_shape, desc: fields.shapeDescDisplay};
      const newGuess = [...this.props.guess];
      const guessIndex = newGuess.findIndex(({id}) => Number(id) === Number(guessUpdate.id));
      if (guessIndex === -1) {
        newGuess.push(guessUpdate);
        this.props.getMaxOrder(fields.id_shape + 1);
      } else {
        newGuess[guessIndex] = guessUpdate;
      }
      this.props.getArrayGuess(newGuess);
    }
    this.setState({
      editVisible: false
    });
	  this.renderJsonData();
  }

  handleSaveArrow = (arrowStyle) => {
    this.saveHistory();
    if (!arrowStyle) return;
    const { graph } = this.state;
    let styleDashed = '0';
    let startArrow = 'none';
    switch(arrowStyle) {
      case 'line1': {
        styleDashed = 0;
        startArrow = 'none';
        break;
      }
      case 'line2': {
        styleDashed = 0;
        startArrow = mxConstants.ARROW_CLASSIC;
        break;
      }
      case 'dot1': {
        styleDashed = 1;
        startArrow = 'none';
        break;
      }
      case 'dot2': {
        styleDashed = 1;
        startArrow = mxConstants.ARROW_CLASSIC;
        break;
      }
      default: break;
    }
    graph.setCellStyles(mxConstants.STYLE_DASHED, styleDashed);
    graph.setCellStyles(mxConstants.STYLE_STARTARROW, startArrow);
    this.setState({
      graph,
      editArrowVisible: false
    });
    this.renderJsonData();
  }

  handleDeleteShape = (fields) => {
    this.saveHistory();
    const { graph } = this.state;
    const cell = graph.getSelectionCell();
    if (fields.id_shape !== '') {
      const guessDelete = {id_shape: fields.id_shape};
      const newGuess = [...this.props.guess];
      const guessIndex = newGuess.findIndex(({id_shape}) => id_shape === guessDelete.id_shape);
      if (guessIndex !== -1) {
          newGuess.splice(guessIndex, 1);
      }
      this.props.getArrayGuess(newGuess);
    }
    graph.removeCells([cell]);

    // mxEvent.consume(evt);
    this.setState({ editVisible: false });
    this.renderJsonData();
  }

  graphF = evt => {
    const { graph } = this.state;
    var x = mxEvent.getClientX(evt);
    var y = mxEvent.getClientY(evt);
    var elt = document.elementFromPoint(x, y);
    if (mxUtils.isAncestorNode(graph.container, elt)) {
      return graph;
    }
    return null;
  };

  getEditPreview = () => {
    // var dragElt = document.createElement("div");
    // dragElt.style.border = "dashed black 1px";
    // dragElt.style.width = "120px";
    // dragElt.style.height = "40px";
    // return dragElt;
  };

  getObjectDefault = (value) => {
    var doc = mxUtils.createXmlDocument();
    var obj = doc.createElement("ShapeObject");
    obj.setAttribute("label", '');
    obj.setAttribute("text", '');
	  return obj;
  };

  selectionChanged = (graph, value) => {
    this.setState({
      createVisible: true,
      currentNode: graph.getSelectionCell(),
      currentTask: value
    });
  };

  createPopupMenu = (graph, menu, cell, evt) => {
    this.setState({
      editVisible: true,
    }, () => {
      this.getShapeDetail(graph, menu, cell, evt)
    })
  };

  openArrowEdit = (graph, menu, cell, evt) => {
    this.setState({
      editArrowVisible: true,
    }, () => {
      // this.getShapeDetail(graph, menu, cell, evt)
    })
  };

  handleDeleteArrow = () => {
    this.saveHistory();
    const self = this;
    confirm({
      title: 'この矢印を削除してもいいですか。',
      okText: 'OK',
      okType: 'danger',
      cancelText: 'キャンセル',
      onOk() {
        const { graph } = self.state;
        const cell = graph.getSelectionCell();
        graph.removeCells([cell]);
        self.renderJsonData();
        self.setState({
          editArrowVisible: false,
        })
      },
      onCancel(){},
    });
  }

  getShapeDetail = (graph, menu, cell, evt) => {
    const shape = {
      type: cell.value.attributes[0].value,
      title: cell.value.attributes[1].value,
      desc: cell.value.attributes[2].value,
      title_display: cell.value.attributes[3].value,
      desc_display: cell.value.attributes[4].value,
      id_shape: cell.value.attributes[5].value !== '' ? parseInt(cell.value.attributes[5].value) : '',
      max_order: this.props.maxOrder,
      id: cell.id
    }
    this.setState({
      currentShape: shape
    })
  }

  funct = (graph, evt, target, x, y, value) => {
    this.saveHistory();
    var doc = mxUtils.createXmlDocument();
    var obj = doc.createElement("ShapeObject");
    obj.setAttribute("label", value);
    obj.setAttribute("text", "");
    obj.setAttribute("desc", "");

    var parent = graph.getDefaultParent();
    let cell = graph.insertVertex(
      parent,
      target,
      obj,
      x,
      y,
      200,
      40,
    );
    graph.setSelectionCell(cell);
    this.selectionChanged(graph, value);
  };

  functArrow = (graph, evt, target, x, y, value) => {
    this.saveHistory();
    var parent = graph.getDefaultParent();
    var name = 'default' + x + y ;
    var defaultObject = this.getObjectDefault(name);
    var cell1 = graph.insertVertex(parent,target, defaultObject, x, y, 0, 0);
    var cell2 = graph.insertVertex(parent,target, defaultObject, x + 100, y, 0, 0);
    var e1 = graph.insertEdge(parent, null, 'EdgeD', cell1, cell2,
      'strokeColor=#555555;strokeWidth=1;endArrow=classic;endSize=8;edgeStyle=elbowEdgeStyle;');
    e1.geometry.points = [new mxPoint(100, 100)];
    this.renderJsonData();
  };

  selectionChange = (sender, evt) => {
    const {selectingArrow, selectingBoxCell} = this.state;
    const selectedCell = sender.cells.length && sender.cells[0].value;
    if(selectedCell){
      this.setState({selectingArrow: true});
    }else if(selectingArrow){
      this.setState({selectingArrow: false});
    }

    if((!selectedCell || typeof selectedCell !== 'object') && selectingBoxCell){
      this.setState({selectingBoxCell: false})
    }else if(typeof selectedCell === 'object'){
      this.setState({selectingBoxCell: true})
    }
  };

  onClickColorSelector = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker
    });
  }

  onClickUndo = () => {
    const { histories, graph, highlightList } = this.state;
    let preGraph;
    if (histories.length > 1) {
      preGraph = histories.pop();
    } else if (histories.length === 1) {
      preGraph = histories[0];
      this.setState({ enableUndo: false });
    }
    graph.getModel().clear();
    const graphRender = JSON.parse(preGraph);
    this.renderJSON(graphRender, graph);

    //render guess part
    let guessRender = [];
    graphRender.graph.map(item => {
      if (item.value.id_shape !== undefined && item.value.desc_display !== undefined && item.value.id_shape !== '' && item.value.label !== "ShapeBoxCause") {
        guessRender = [...guessRender, {
          id: item.id,
          id_shape: parseInt(item.value.id_shape),
          desc: item.value.desc_display
        }];
      }
    });
    guessRender.sort((a,b) => a.id_shape - b.id_shape);
    this.props.getArrayGuess(guessRender);
    setTimeout(() => {
      guessRender.map((item) => {
        // let highlight = new mxCellHighlight(graph, '#000', 1);
        let graphModal = graph.getModel();
        let target = graphModal.getCell(item.id);

        let tStyle = target.getStyle();
        if (!tStyle) {
          tStyle = '';
        }
        const arrStyle = tStyle.split(';');
        let strokeWidth = 1;
        let dashed = 0;
        for (var i = 0; i < arrStyle.length; i++) {
          if (arrStyle[i]) {
            let itemStyle = arrStyle[i].split('=');
            if (itemStyle[0] == 'dashed' && itemStyle[1]) {
              dashed = parseInt(itemStyle[1]);
            }
            if (itemStyle[0] == 'strokeWidth' && itemStyle[1]) {
              strokeWidth = parseInt(itemStyle[1]);
            }
          }
        }
        const highlight = new mxCellHighlight(graph, '#000', strokeWidth, dashed);
        highlight.spacing = strokeWidth * 2;
        highlight.highlight(graph.view.getState(target));
        highlightList[item.id] = highlight;
        this.setState({
          highlightList: highlightList
        });
      })
    }, 100);
  }

  setSelectedBoxColor = (color) => {
    this.saveHistory();
    const { graph } = this.state
    const target = graph.getSelectionCell();
    this.handleChangeArrowColor(color, target);
    graph.setCellStyles(mxConstants.STYLE_FILLCOLOR, color, [target]);
    this.setState({ graph });
    this.renderJsonData();
  }

  handleChangeArrowColor = (color, target) => {
    const { graph } = this.state;
    if(graph && target && (target.value === 'Edge' || target.value === 'EdgeD')){
      graph.setCellStyles('strokeColor', color, [target]);
    }
  }

 

  handleChangeLineWidth = (size = 1) => {
    this.saveHistory();
    const {highlightList} = this.state;
    const { graph } = this.state;
    const target = graph.getSelectionCell();
    const highlight = highlightList[target.id];
    if(graph && target){
      graph.setCellStyles('strokeWidth', size, [target]);
      if (highlight) {
        highlight.destroy();
        let tStyle = target.getStyle();
        if (!tStyle) {
          tStyle = '';
        }
        const arrStyle = tStyle.split(';');
        let strokeWidth = 1;
        let dashed = 0;
        for (var i = 0; i < arrStyle.length; i++) {
          if (arrStyle[i]) {
            let itemStyle = arrStyle[i].split('=');
            if (itemStyle[0] == 'dashed' && itemStyle[1]) {
              dashed = parseInt(itemStyle[1]);
            }
            if (itemStyle[0] == 'strokeWidth' && itemStyle[1]) {
              strokeWidth = parseInt(itemStyle[1]);
            }
          }
        }
        const highlight2 = new mxCellHighlight(graph, '#000', size, dashed);
        highlight2.highlight(graph.view.getState(target));
        highlight2.spacing = size * 2;
        highlightList[target.id] = highlight2;
        this.setState({
          highlightList: highlightList
        });
      }

      this.renderJsonData();
      this.setState({ graph });
    }
  }

  handleChangeDotLine = (dot = 1) => {
    this.saveHistory();
    const {highlightList} = this.state;
    const { graph } = this.state;
    const target = graph.getSelectionCell();
    const highlight = highlightList[target.id];
    if(graph && target){
      graph.setCellStyles(mxConstants.STYLE_DASHED, dot);
      if (highlight) {
        highlight.destroy();
        let tStyle = target.getStyle();
        if (!tStyle) {
          tStyle = '';
        }
        const arrStyle = tStyle.split(';');
        let strokeWidth = 1;
        let dashed = 0;
        for (var i = 0; i < arrStyle.length; i++) {
          if (arrStyle[i]) {
            let itemStyle = arrStyle[i].split('=');
            if (itemStyle[0] == 'dashed' && itemStyle[1]) {
              dashed = parseInt(itemStyle[1]);
            }
            if (itemStyle[0] == 'strokeWidth' && itemStyle[1]) {
              strokeWidth = parseInt(itemStyle[1]);
            }
          }
        }
        const highlight2 = new mxCellHighlight(graph, '#000', strokeWidth, dot);
        highlight2.highlight(graph.view.getState(target));
        highlight2.spacing = strokeWidth * 2;
        highlightList[target.id] = highlight2;
        this.setState({
          highlightList: highlightList
        });
      }
      this.renderJsonData();
      this.setState({ graph });
    }
  }

  toggleLineWidthPicker = () => {
    const { showLineWidthPicker } = this.state;
    this.setState({ showLineWidthPicker: !showLineWidthPicker })
  }

  toggleDotLinePicker = () => {
    const { showDotLinePicker } = this.state;
    this.setState({ showDotLinePicker: !showDotLinePicker })
  }

  mouseUpFunction = (sender, evt) => {
    const { graph } = this.state
    this.props.handleClickWorkingSlide();
    graph.resizeContainer = true
    this.renderJsonData();
    var graphModal = graph.getModel();
    var currentCell = graph.getSelectionCell();
    var source = null;
    var target = null;
    if (currentCell && typeof currentCell.value !== 'object') {
      var currentCellSourceGeometry = currentCell.getGeometry().getTerminalPoint(true);
      var currentCellTargetGeometry = currentCell.getGeometry().getTerminalPoint(false);
      if (currentCell.value === 'Edge') {
        var parent = this.state.graph.getDefaultParent();
        var defaultObject = this.getObjectDefault('default');
        var defaultXSource = currentCellSourceGeometry ? currentCellSourceGeometry.x : 0;
        var defaultYSource = currentCellSourceGeometry ? currentCellSourceGeometry.y : 0;
        var defaultXTarget = currentCellTargetGeometry ? currentCellTargetGeometry.x : 0;
        var defaultYTarget = currentCellTargetGeometry ? currentCellTargetGeometry.y : 0;
        var isDeleteEdge = false;
        if (!currentCell.source) {
          isDeleteEdge = true;
          const cellDefault = this.state.graph.insertVertex(parent,null, defaultObject, defaultXSource, defaultYSource, 0, 0);
          source = graphModal.getCell(cellDefault.id);
        } else {
          source = graphModal.getCell(currentCell.source.id);
        }
        if (!currentCell.target) {
          isDeleteEdge = true;
          const cellDefault = this.state.graph.insertVertex(parent,null, defaultObject, defaultXTarget, defaultYTarget, 0, 0);
          target = graphModal.getCell(cellDefault.id);
        } else {
          target = graphModal.getCell(currentCell.target.id);
        }
        if(isDeleteEdge) {
          this.state.graph.removeCells([currentCell]);
          currentCell = this.state.graph.insertEdge(parent, null, 'Edge', source, target,
          'strokeColor=#555555;strokeWidth=1;endArrow=classic;endSize=8;edgeStyle=elbowEdgeStyle;');
        }
      } else {
        if (!currentCell.source) {
          source = graphModal.getCell(currentCell.id-2);
        } else {
          source = graphModal.getCell(currentCell.source.id);
        }
        if (!currentCell.target) {
          target = graphModal.getCell(currentCell.id-1);
        } else {
          target = graphModal.getCell(currentCell.target.id);
        }
      }
      var sourceGeomety = source.getGeometry();
      var targetGeomety = target.getGeometry();
      if (currentCellSourceGeometry) {
        sourceGeomety.x = currentCellSourceGeometry.x;
        sourceGeomety.y = currentCellSourceGeometry.y;
      }
      if (currentCellTargetGeometry) {
        targetGeomety.x = currentCellTargetGeometry.x;
        targetGeomety.y = currentCellTargetGeometry.y;
      }
      source.setGeometry(sourceGeomety);
      target.setGeometry(targetGeomety);
      graphModal.terminalForCellChanged(currentCell, source, true);
      graphModal.terminalForCellChanged(currentCell, target, false);
    } else if (currentCell && typeof currentCell.value === 'object') {
      this.setState({ selectingBoxCell: true })
    }
    this.renderJsonData();
  }

  renderJsonData = () => {
    //scroll to cell to visible
    this.autoScrollCellToVisiable();
    var jsonNodes = this.getJsonModel(this.state.graph);
    let jsonStr = this.stringifyWithoutCircular(jsonNodes);
    localStorage.setItem("json", jsonStr);
    this.props.handleViewJson(jsonStr);
  }

  saveHistory = () => {
    const { histories } = this.state;
    var jsonNodes = this.getJsonModel(this.state.graph);
    let jsonStr = this.stringifyWithoutCircular(jsonNodes);
    histories.push(jsonStr);
    const enableUndo = histories.length > 1 ? true : false;
    this.setState({ histories, enableUndo });
  }

  updateShape = (shape, newIndex) => {
    this.saveHistory();
    const { graph } = this.state;
    const graphModal = graph.getModel();
    const target = graphModal.getCell(shape.id);
    const targetText = target.value.getAttribute('text_display');
    const newTargetText = targetText.replace(/#.*\./, `#${newIndex}.`);
    this.applyHandler(graph, target, "text_display", newTargetText);
    this.applyHandler(graph, target, "desc_display", shape.desc);
    this.applyHandler(graph, target, "id_shape", newIndex);
    this.renderJsonData();
  }

  handleFullScreen = () => {
    const { toggleFullScreen  } = this.props;
    if(typeof toggleFullScreen === 'function'){
      toggleFullScreen();
    }

  }

  togglePrintPreviewPopup = () => {
    this.setState({
      loading: true
    })
    setTimeout(this.getImageFromGraph)
  }

  getImageFromGraph = () => {
      const node = this.graphContainerRef.current;
      let scale = 2;
      if(node && this.printReviewRef){
        domToPng(node, {
          width: node.clientWidth * scale,
          height: node.clientHeight * scale,
          style: { transform: 'scale('+scale+')', transformOrigin: 'top left'}

        }).then(src => {
          const image = new Image();
          image.src = src;
          image.onload = () => {
            this.slidePreviewImage = image
            this.setState({ 
              loading: false,
              isOpenPrintPreview: true
            })
            this.drawPreviewSideImage();
          }
        }).catch((error) => {
          console.error("Error while generating image:", error);
          this.setState({ loading: false });
      })
    }
  }

  handleSavePreviewImage = () => {
    var canvas = this.printReviewRef
    var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.
    var link = document.createElement('a');
    link.href = image;
    link.download = 'slide_image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  handlePrintSlide = () => {
    window.print();
  }

  drawPreviewSideImage = (pos = { x : 0, y: 0 }, scale = 0) => {
    if(!this.slidePreviewImage) return;
    const text = {
      leftTop: '身体的側面',
      leftBottom: '環境・生活の側面',
      rightTop: '心理的側面',
      rightBottom: '家族・介護状況の側面'
    }
    const image = this.slidePreviewImage;
    const canvas = this.printReviewRef;
    const ratio = window.devicePixelRatio || 1;

    const scaleFactor = 2;
    canvas.width = window.innerWidth * ratio * scaleFactor;
    canvas.height = window.innerHeight * ratio * scaleFactor;
    const ctx = canvas.getContext("2d");

    let w = (canvas.width / ratio) + 40 * scale;
    let h = (canvas.height / ratio) + 40 * (scale * (canvas.height / canvas.width));
    let x = 0 - (w - canvas.width / ratio) / 2 + pos.x;
    let y = 0 - (h - canvas.height / ratio) / 2 + pos.y;

    ctx.fillStyle = '#FFF';
    ctx.fillRect(0,0, canvas.width / ratio, canvas.height / ratio);
    ctx.drawImage(image, x, y, w, h);
    ctx.fillStyle = "#555555";
    ctx.font = "bold 24px Arial";
    ctx.fillText(text.leftTop, 30, 45);
    ctx.fillText(text.leftBottom, 30, (canvas.height / ratio) - 30);
    ctx.fillText(text.rightBottom, (canvas.width / ratio) - 270, (canvas.height / ratio) - 120);
    ctx.fillText(text.rightTop, (canvas.width / ratio) - 150, 45);
  }

  checkMoveSlideAble = ({x, y, w, h}) => {
    const { preventSlideMove } = this.state;
    const canvas = this.printReviewRef;
    let move = {
      ...preventSlideMove
    }
    if((Math.abs(x) - 20) < 0){
      move = {
        ...move,
        left : true
      }
    }else if(((Math.abs(x) + canvas.width) - w) > 0){
      move = {
        ...move,
        right: true
      }
    }
    if((Math.abs(y) - 20) < 0){
      move = {
        ...move,
        down: true
      }
    }else if(((Math.abs(y) + canvas.height) - h) > 0){
      move = {
        ...move,
        up: true
      }
    }
    return move
  }

  handleSlidePreviewMove = (type) => {
    const { slidePos, slideScale, preventSlideMove } = this.state;
    switch(type){
      case 'left':{
        if(preventSlideMove.left) return
        this.setState({
          slidePos: {...slidePos, x: slidePos.x + 20}
        })
        this.drawPreviewSideImage({...slidePos, x: slidePos.x + 20}, slideScale)
        break;
      }
      case 'right':{
        if(preventSlideMove.right) return
        this.setState({
          slidePos: {...slidePos, x: slidePos.x - 20}
        })
        this.drawPreviewSideImage({...slidePos, x: slidePos.x - 20}, slideScale)
        break;
       
      }
      case 'up':{
        if(preventSlideMove.up) return
        this.setState({
          slidePos: {...slidePos, y: slidePos.y + 20}
        })
        this.drawPreviewSideImage({...slidePos, y: slidePos.y + 20}, slideScale)
        break;
      }
      case 'down': {
        if(preventSlideMove.down) return
        
        this.setState({
          slidePos: {...slidePos, y: slidePos.y - 20}
        })
        this.drawPreviewSideImage({...slidePos, y: slidePos.y - 20}, slideScale)
        break;
      }
      default:
        break;
    }
  }

  handleSlidePreviewZoom = (type) => {
    const { slideScale, slidePos } = this.state;
    if(type === 'in'){
      if(slideScale === 60) return;
      this.setState({
        slideScale: slideScale + 1
      })
      this.drawPreviewSideImage(slidePos, slideScale + 1)
    }else{
      this.setState({
        slideScale: slideScale - 1
      })
      this.drawPreviewSideImage(slidePos, slideScale - 1)
    }
  }

  handleUndoSlideControl = () => {
    this.setState({slideScale: 0, slidePos: {x: 0, y: 0}})
    this.drawPreviewSideImage({x: 0, y: 0}, 0)
  }

  handleBringCellToFront = () => {
    const { graph } = this.state
    if(graph){
      this.saveHistory();
      const selectedCell = graph.getSelectionCell()
      graph.orderCells(false, [selectedCell])
      this.renderJsonData();
    }
  }

  handleSendCellToBack = () => {
    const { graph } = this.state
    if(graph){
      this.saveHistory();
      const selectedCell = graph.getSelectionCell()
      graph.orderCells(true, [selectedCell])
      this.renderJsonData();
    }
  }
  
  handleCreateBox = (e) => {
    let value = BOX_TYPE.box_cause;
    if(this.shiftKeyPressed) value = BOX_TYPE.box_care
    const {left, top } = e.target.getBoundingClientRect();
    const { graph } = this.state;
    if(!graph || !value) return
    graph.getModel().beginUpdate();
    let pos = {
      x: (e.clientX - left) - 100,
      y: (e.clientY - top) - 20
    }
    this.funct(graph, null, null, pos.x, pos.y, value);  
    graph.getModel().endUpdate();
  }

  render() {
    const { isGraphEditable, labelPosition, error, windowDimension } = this.props
    const { containerDimension, loading } = this.state
    return (
      
      <div className='card-working'>
        <Loading loading={loading} />
        <Card className='card-container card-action'>
          <Row type='flex' justify='space-between'>
            <Col>
              <div className='button-left'>
                <button className='btn-shape box-cause'
                        ref={this.mxSidebarBoxCauseRef}
                        disabled={!isGraphEditable}
                        data-title={BOX_TYPE.box_cause}
                        data-value={BOX_TYPE.box_cause} 
                >
                  <img className="icon" src={iconShape}  alt='icon-shape'/> 要因
                </button>
                <button className='btn-shape box-care'
                        ref={this.mxSidebarBoxCareRef}
                        disabled={!isGraphEditable}
                        data-title={BOX_TYPE.box_care}
                        data-value={BOX_TYPE.box_care}
                >
                  <img className="icon" src={iconShape}  alt='icon-shape'/> 看護問題
                </button>
                <button className='btn-connector'
                        ref={this.mxConnectorRef}
                        disabled={!isGraphEditable}
                        data-title="Arrow"
                        data-value="Arrow"
                >
                  <img className="icon" src={iconArrow} alt='icon-arrow'/> 矢
                </button>
                <button className='btn-connector'
                        data-title="Undo"
                        data-value="Undo"
                        onClick={this.onClickUndo}
                        disabled={!this.state.enableUndo}
                >
                  <img className="icon" src={iconUndo} alt='icon-undo'/> 元に戻す
                </button>
                <div className='btn-select-weight-area'>
                    <button disabled={!this.state.selectingArrow} ref={this.lineWeightButtonRef} className={'btn-select-weight'} onClick={this.toggleLineWidthPicker}>
                        <div className={'btn-weight-line'}></div>
                    </button>
                    <div className={`line-weight-select ${this.state.showLineWidthPicker ? 'show': ''}`}>
                        {
                            LINE_WEIGHTS.map(line => (
                                <div key={line} onClick={() => this.handleChangeLineWidth(line)} className={'line-weight-option'}>
                                    <div style={{ height: line }}></div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <button className='btn-order-action' style={{ display: 'none'}} onClick={this.handleBringCellToFront}>
                  <img className="icon" src={iconBringToFront} alt='icon-bring-to-front'/>
                </button>
                <button className='btn-order-action' style={{ display: 'none'}} onClick={this.handleSendCellToBack}>
                <img className="icon" src={iconSendToBack} alt='icon-send-to-back'/>
                </button>
                <div className="color-selector-area">
                  <button
                    className='btn-select-color'
                    ref={this.colorButtonRef}
                    disabled={(!this.state.selectingBoxCell && !this.state.selectingArrow)}
                    data-title="ColorSelector"
                    data-value="ColorSelector"
                    onClick={this.onClickColorSelector}
                  >
                    <BgColorsOutlined />
                  </button>
                  <div className={"color-picker " + (this.state.showColorPicker ? "show": "")}>
                    {
                      Object.keys(BOX_COLORS).map((colorTitle) =>
                        ( 
                          <div 
                            onClick={this.setSelectedBoxColor.bind(this, BOX_COLORS[colorTitle])}
                            key={colorTitle}
                            className="color-element" style={{ backgroundColor: BOX_COLORS[colorTitle]}}>
                          </div>
                        )
                      )
                    }
                  </div>
                </div>
                <div className='btn-select-weight-area'>
                    <button disabled={!this.state.selectingArrow} ref={this.dotLineButtonRef} className={'btn-select-weight'} onClick={this.toggleDotLinePicker}>
                        <div className={'btn-dot-line'}></div>
                    </button>
                    <div className={`dot-line-select ${this.state.showDotLinePicker ? 'show': ''}`}>
                        {
                          DOT_WEIGHTS.map(line => (
                                <div key={line} onClick={() => this.handleChangeDotLine(line)} className={'dot-line-option'}>
                                    <div className={'dot-line-option-' + line}></div>
                                </div>
                            ))
                        }
                    </div>
                </div>
              </div>
            </Col>
            <Col className={'toolbar-right-col'}>
              <div className="toolbar button-right" ref={this.toolbarRef} />
              <button className={'btn-fullscreen'} onClick={this.handleFullScreen}>
                { !this.props.isFullScreen ? <FullscreenOutlined /> : <FullscreenExitOutlined  />}
              </button>
              <button className='ml-1' onClick={this.togglePrintPreviewPopup}>
                <PrinterOutlined  />
              </button>
            </Col>
          </Row>
        </Card>
        <div className={`working-part-illus ${this.props.isFullScreen ? 'fullscreen-mode' : ''}`}>
          <span className='working-label working-label-1'
                style={{
                  top: this.props.isFullScreen ? (error ? (56 + 20): (56 + 10)):(error ? (182 + 10 + 20): (182 + 10))
                }}
          >身体的側面</span>
          <span className='working-label working-label-2'
                style={{
                  right: labelPosition ? "21%" : (10 + 10),
                  top: this.props.isFullScreen ? (error ? (56 + 20): (56 + 10)):(error ? (182 + 10 + 20): (182 + 10))
                }}
          >心理的側面</span>
          <span className='working-label working-label-3'>環境・生活の側面</span>
          <span className='working-label working-label-4'
                style={{
                  right: labelPosition ? "21%" : (10 + 10),
                  bottom: labelPosition ? 15 : 90
                }}
          >家族・介護状況の側面</span>
        </div>
        <div className={`working-part-graph ${this.props.isFullScreen ? 'fullscreen-mode': ''}`}>
          <div className="graphContainer"
               ref={this.graphContainerRef}
               onDoubleClick={this.handleCreateBox}
               style={{
                 width: containerDimension.width,
                 height: containerDimension.height,
                 minWidth: windowDimension.width,
                 minHeight: windowDimension.height,
               }}
          />

        </div>
        <div className="changeInput" style={{ zIndex: 10 }} />
        <FullScreen 
            showControl={this.state.isOpenPrintPreview}
            isFullScreen={this.state.isOpenPrintPreview}
            renderControl={() => (
              <div className="control-canvas-container">
              <ControlButton
                onMove={this.handleSlidePreviewMove}
              />
              <ZoomButton onZoom={this.handleSlidePreviewZoom} />
              <button onClick={this.handleUndoSlideControl} className='btn-undo'>
                <UndoOutlined style={{ color: "#7f7f7f"}} />
              </button>
            </div>
            )}
            renderAction={() => (
              <>
                <Button onClick={() => this.setState({ isOpenPrintPreview: false })} className="ml-1">キャンセル</Button>
                <Button onClick={this.handleSavePreviewImage} className="ml-1" type="primary">画像をダウンロード</Button>
                <Button onClick={this.handlePrintSlide} className="ml-1" type="primary">印刷</Button>
              </>
            )}
        >
            <canvas className="preview-canvas" ref={ref => this.printReviewRef = ref}></canvas>
        </FullScreen>
        {this.state.createVisible && isGraphEditable && (
          <CreateShape
            currentTask={this.state.currentTask}
            visible={this.state.createVisible}
            handleCancel={this.handleCancel}
            handleConfirm={this.handleConfirm}
            maxOrder = {this.props.maxOrder}
          />
        )}
        
        {this.state.editVisible && isGraphEditable && (
          <EditShape
            currentShape={this.state.currentShape}
            visible={this.state.editVisible}
            handleCancelEdit={this.handleCancelEdit}
            handleSaveShape={this.handleSaveShape}
            handleDeleteShape={this.handleDeleteShape}
          />
        )}

        {this.state.editArrowVisible && isGraphEditable && (
          <EditArrow
            visible={this.state.editArrowVisible}
            handleCancelEdit={this.handleCancelArrowEdit}
            handleSaveArrow={this.handleSaveArrow}
            handleDeleteArrow={this.handleDeleteArrow}
          />
        )}
      </div>
    );
  }
}

MxGraphGridAreaEditor.propTypes = {
  handleViewJson: PropTypes.func,
  workingSlide: PropTypes.any,
  isGraphEditable: PropTypes.bool
}

export default MxGraphGridAreaEditor;
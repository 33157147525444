import React from "react";
import {Modal, Form, Input, message, Button, Icon} from "antd";
import {Helper} from "../../utils/Helper";
import { API_URL } from '../../config/api.js';
import Axios from "axios";
import { Auth } from "aws-amplify";
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 18}
  }
};

let isBtnSaveDisabled = false
let errorMsg = ''
const loadingIcon = <Icon type='loading' />

const EditFolder = Form.create()(
  class extends React.Component {
    state = {
      isLoading: false,
      folder: {
        name: "",
        id: "",
      },
      error: false,
    };
    
    componentDidMount() {
      message.config({
        top: 100,
        duration: 1.5
      });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
      if (this.props.currentFolder !== nextProps.currentFolder) {
        this.setState({
          folder: {
            name: nextProps.currentFolder.name,
            id: nextProps.currentFolder.key,
          }
        })
      }
    }

    onFieldChange(field, e) {
      const folder = this.state.folder
      const newFolder = { ...folder }

      if (field === 'folderName') {
        newFolder['name'] = Helper.trimString(e.target.value)
        const len = newFolder['name'].length
        if (len === 0) {
          isBtnSaveDisabled = true
          this.setState({error: true})
          errorMsg = 'フォルダ名を入力してください。'
        } else if (len > 30) {
          isBtnSaveDisabled = true
          this.setState({error: true})
          errorMsg = 'フォルダ名は30文字以内で入力してください。'
        } else {
          isBtnSaveDisabled = false
          this.setState({error: false})
        }
      }

      this.setState({
        folder: newFolder
      });
    }

    hideModal = () => {
      this.props.handleCancelEdit();
    };

    handleSubmit =  async () => {

      this.setState({
        isLoading: true
      });
      isBtnSaveDisabled = true

      try {
        const { folder } = this.state;
        const folderId = folder.id;
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        var username = user.username ? user.username : localStorage.getItem('username');
        var data = {
          ...folder
        };
        Axios.put(`${API_URL}/folders/${folderId}`, { ...data })
          .then(res => {
            this.setState({
              isLoading: false,
              folder: {
                name: "",
              },
            });
            this.props.handleConfirm();
          })
          .catch(err => {
            console.log(err)
          })
      } catch(err) {
        console.log(err)
      }
    };

    
    render() {
      const { folder, error, isLoading } = this.state;

      return (
        <div>
          <Modal
            title="フォルダの修正"
            visible={this.props.visible}
            onCancel={this.hideModal}
            okText="保存"
            cancelText="キャンセル"
            width="620px"
            className="modal-slide"
            footer={[
              <Button key="2" onClick={this.hideModal}>キャンセル</Button>,
              <Button type="primary" key="3" onClick={this.handleSubmit} disabled={isBtnSaveDisabled}>
                {isLoading ? loadingIcon : ""} 保存
              </Button>
            ]}
          >
            <Form>
              <FormItem
                label="フォルダ名"
                required
                layout="horizontal"
                {...formItemLayout}
                className={error ? 'has-error' : ''}
              >
                <Input value={folder.name}
                       onChange={e => this.onFieldChange('folderName', e)}
                       name="folderName"
                       id='edit'
                />
                {error && errorMsg && <p className='ant-form-explain'>{errorMsg}</p>}
              </FormItem>
            </Form>
          </Modal>
        </div>
      );
    }
  }
);

export default EditFolder;

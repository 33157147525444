/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
 
const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:d42f4571-0471-43e9-a1f8-9d1596f537eb",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_Sy9gWiCPk",
    "aws_user_pools_web_client_id": "1ae9j3pj3hut7edn65bm2jk3r8",
    "oauth": {
        "domain": "slideapp504669c9-504669c9-dev.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://slide.passtell.jp/",
        "redirectSignOut": "https://slide.passtell.jp/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS"
};


export default awsmobile;

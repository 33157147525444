import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import "antd/dist/antd.css";
import Amplify from "aws-amplify";
import awsconfig from "../../aws-exports";
import routes from "../../routes";
import { createBrowserHistory } from 'history';

Amplify.configure(awsconfig);

function App() {
  const history = createBrowserHistory();
  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props =>
          <Component {...props} />
        }
      />
    );
  }

  const showContent = (routes) => {
    var result = null;
    if (routes.length > 0) {
      result = routes.map((route, index) => {
        return (
          route.auth === true ? (
            <PrivateRoute
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.main}
            />
          ) : (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.main}
              />
            )
        );
      });
    }
    return result;
  }

  return (
    <Router history={history}>
      <Switch>
        {showContent(routes)}
      </Switch>
    </Router>
  );
}

export default App;
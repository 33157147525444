import { Row, Col, Button, Typography } from "antd";
import React from "react";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import "./Login.scss";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "../../aws-exports";
import { useHistory } from "react-router-dom";
import LoginTextImg from '../../assets/copy.png';
import LoginImg1 from '../../assets/flour01.png';
import LoginImg2 from '../../assets/flour02.png';
import LoginImg3 from '../../assets/illust01.png';
import LoginImg4 from '../../assets/illust02.png';
import LoginImg5 from '../../assets/illust03.png';
import LoginImg6 from '../../assets/illust04.png';

const Title = Typography.Title;
Amplify.configure(awsconfig);

function Login() {
  const history = useHistory();
  let { from } = history.location.state || { from: { pathname: "/" } };

  // Check is Authenticate
  return localStorage.getItem("token") ? (
    <Redirect to={from} />
  ) : (
    <Router>
      <main className="container">
        <div className='login-content'>
          <img src={LoginTextImg} alt='login image text' />
          <img src={LoginImg1} alt='login image 1' />
          <img src={LoginImg2} alt='login image 2' />
          <img src={LoginImg3} alt='login image 3' />
          <img src={LoginImg4} alt='login image 4' />
          <img src={LoginImg5} alt='login image 5' />
          <img src={LoginImg6} alt='login image 6' />
          <Title level={2} className='login-content__title'>大学のメールアドレスでログイン</Title>
          <Button
            type="primary"
            htmlType="button"
            className="btn-login"
            onClick={() => Auth.federatedSignIn({ provider: "Google" })}
          >
            &nbsp;Googleでログイン
          </Button>
        </div>
      </main>
    </Router>
  );
}

export default Login;

import React from 'react';
import Home from './pages/Home';
import Login from './pages/Login';
// import SignUp from './pages/Register/SignUp';
// import ConfirmSignUp from './pages/Register/ConfirmSignUp';
// import ForgotPassword from './pages/ForgotPassword';
// import ChangePassword from './pages/ForgotPassword/ChangePassword';
import ConfirmPassword from './pages/ConfirmPassword/index';
import SlideForm from "./pages/Slide/components/SlideForm";
import SlideDetail from "./pages/Slide/SlideDetail";
import FolderDetail from './pages/FolderDetail';

const routes = [
  {
    path: '/login',
    exact: true,
    auth: false,
    main: ({ location }) => <Login location={location} />
  },
  {
    path: '/confirm-password',
    exact: true,
    auth: false,
    main: ({ location }) => <ConfirmPassword location={location} />
  },
  {
    path: '/folders/:folderId/slides/create',
    exact: true,
    auth: true,
    main: ({ location }) => <SlideForm location={location} />
  },
  {
    path: '/folders/:folderId/slides/edit/:slideId',
    exact: true,
    auth: true,
    main: ({ location }) => <SlideDetail location={location} />
  },
  {
    path: '/',
    exact: true,
    auth: true,
    main: ({ location }) => <Home location={location} />
  },
  {
    path: '/folders',
    exact: true,
    auth: true,
    main: ({ location }) => <Home location={location} />
  },
  {
    path: '/folders/detail/:folderId',
    exact: true,
    auth: true,
    main: ({ location }) => {
      return  <FolderDetail key={location.key} location={location} />
    }
  },
];

export default routes;
import React from 'react';
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

function ZoomButton({ onZoom }) {
  function handleZoom(type) {
    if (typeof onZoom === "function") {
      onZoom(type);
    }
  }
  return (
    <div className="btn-zoom-container">
      <button onClick={() => handleZoom("in")} className="btn-zoom-in">
        <PlusOutlined style={{ color: "#7f7f7f" }} />
      </button>
      <button onClick={() => handleZoom("out")} className="btn-zoom-out">
        <MinusOutlined style={{ color: "#7f7f7f" }} />
      </button>
    </div>
  );
}

export default ZoomButton;

import React from 'react';
import {
  LeftOutlined,
  RightOutlined,
  DownOutlined,
  UpOutlined
} from "@ant-design/icons";
import './ControlButton.scss';


function ControlButton({ onMove }) {
  function handleMove(type) {
    if (typeof onMove === "function") {
      onMove(type);
    }
  }
  return (
    <div className="btn-control-container">
      <LeftOutlined
        onClick={() => handleMove("left")}
        style={{ color: "#7f7f7f" }}
        className={"control-left"}
      />
      <RightOutlined
        onClick={() => handleMove("right")}
        style={{ color: "#7f7f7f" }}
        className={"control-right"}
      />
      <DownOutlined
        onClick={() => handleMove("down")}
        style={{ color: "#7f7f7f" }}
        className={"control-down"}
      />
      <UpOutlined
        onClick={() => handleMove("up")}
        style={{ color: "#7f7f7f" }}
        className={"control-up"}
      />
    </div>
  );
}

export default ControlButton;

import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom'
import { API_URL } from '../../config/api.js';
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { FolderFilled } from "@ant-design/icons";

import { Table, Input, Row, Icon, Button, Pagination, Empty, Modal, Tooltip } from 'antd';

import "./Slide.css";
import CreateFolder from '../../components/CreateFolder/index.js';
import DeleteFolder from '../../components/DeleteFolder/index.js';
import EditFolder from '../../components/EditFolder/index.js';

const { Search } = Input;
const {confirm} = Modal;

function Slide() {
  const { user } = useSelector((state) => state.user);
  const columns = [
    {
      title: "スライド名",
      dataIndex: "title",
      sorter: true,
      key: "title",
      render: (text, record) => {
        if(record.type === 'folder') {
          return (  <div>
            <FolderFilled style={{ fontSize: "16px", color: "#2F80ED" }} />{" "}
            <a onClick={() => handleRedirectDetail(record)}>{text}</a>
         </div>)
        }
        return text;
      }
    },
    {
      title: "記入者",
      dataIndex: "created_by_username",
      sorter: true,
      key: "created_by_username",
    },
    {
      title: "登録日時",
      dataIndex: "created_at",
      // defaultSortOrder: "descend",
      sorter: true,
      key: "created_at",
    },
    {
      title: "操作",
      dataIndex: "",
      key: "x",
      render: (text, record) => (
        <div>
          <Tooltip title={disabledEdit(record) ? 'View' : 'Edit'}>
            <Button
              type="primary"
              onClick={() => handleRedirectEdit(record)}
            >
              <Icon type="edit" />
            </Button>
          </Tooltip>
          &nbsp;
          <Tooltip title="Delete">
            <Button
              type="danger"
              onClick={() => handleDelete(record)}
              disabled={disabledDelete()}
            >
              <Icon type="delete" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];
  const history = useHistory();
  const [data, setData] = useState();
  const [searchText, setSearchText] = useState('');
  let  folderId  = useParams().folderId;
  const [folderName, setFolderName] = useState('');
  const [sort, setSort] = useState({ field: 'email', order: 'descend' });
  const [paginate, setPaginate] = useState({ current: 1, pageSize: 10, total: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const [createVisible, setCreateVisible] = useState(false);
  const [currentFolder, setCurrentFolder] = useState()
  const [currentParentFolder, setCurrentParentFolder] = useState();
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [currentSelectingFolder, setCurrentSelectingFolder] = useState();

  const handleRedirectDetail = (record) => {
    history.push(`/folders/detail/${record.key}`);
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    if (sorter) {
      fetchData(searchText, paginate.current, paginate.pageSize, sorter.field, sorter.order);
      setSort({ field: sorter.field, order: sorter.order });
    } else {
      fetchData(searchText, paginate.current, paginate.pageSize, sort.field, sort.order);
    }
  }

  const handlePaginationChange = (current, pageSize) => {
    fetchData(searchText, current, pageSize, sort.field, sort.order);
  }

  const fetchData = (search, page, limit, sort, order) => {
    if(page <= 0) page = 1
    setIsLoading(true);
    var query = `?search=${search}&sort=${sort}&order=${order}&page=${page}&limit=${limit}&user_role=${user.role}&created_by=${user.username}`;
    const url = `${API_URL}/folders/detail/${folderId}/${query}`;
    
    fetch(url).then((response) => {
      response.json().then((data) => {
        if (data.folder === null) {
          history.push('/');
          return;
        }
        setFolderName(data.folder.name);
        const dataSource = data.data.map(row => ({
          key: row._id,
          title: row.name || row.title,
          created_by: row.created_by,
          created_by_username: row.created_by_username,
          created_at: row.created_at,
          type: row.name ? 'folder' : 'slide'
        }))
       
        setData(dataSource);
        if (page && limit) {
          setPaginate({ current: page, pageSize: limit, total: data.total });
        }
        setIsLoading(false);
      })
    }).catch(() => {
      history.push('/');
    });
  }

  useEffect(() => {
    fetchData('', 1, 10, 'email', 'descend');
  }, []);

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    paginate.current = 1;
    fetchData(searchText, paginate.current, paginate.pageSize, sort.field, sort.order);
  }

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <a>前</a>;
    }
    if (type === 'next') {
      return <a>次へ</a>;
    }
    return originalElement;
  }

  const handleCreate = () => {
    history.push(`/folders/${folderId}/slides/create`);
  }

  const handleRedirectEdit = (record) => {
    if(record.type === 'folder'){
      setCurrentSelectingFolder({...record, name: record.title, key: record.key})
      setEditVisible(true)
      return;
    }
    history.push(`/folders/${folderId}/slides/edit/${record.key}`);
  }

  const handleRedirectHome = () => {
    history.push('/');
  }

  const handleDelete = (record) => {
    if(record.type === 'folder'){
      setCurrentSelectingFolder({...record, name: record.title, key: record.key})
      setDeleteVisible(true);
      return;
    }
    confirm({
      title: 'スライドを削除してもいいですか',
      okText: 'OK',
      okType: 'danger',
      cancelText: 'キャンセル',
      onOk() {
        const url = `${API_URL}/slides/${record.key}`;
        fetch(url, {
          method: 'DELETE',
        }).then((response) => {
          response.json().then((data) => {
            if (paginate.total % 10 === 1 && paginate.current === Math.floor(paginate.total / 10) + 1) {
              fetchData(searchText, paginate.current - 1, paginate.pageSize, sort.field, sort.order);
            } else {
              fetchData(searchText, paginate.current, paginate.pageSize, sort.field, sort.order);
            }
          })
        })
      },
      onCancel(){},
    });
  }


  const disabledEdit = (record) => {
    if (user.role !== 'admin' && user.username !== record.created_by) {
      return true;
    }
    return false;
  }

  const disabledDelete = () => {
    if (user.role !== 'admin') {
      return true;
    }
    return false;
  }

  useEffect(() => {
    var body = document.getElementsByTagName('body')[0]
    body.setAttribute('class', '')
   
  }, [])
  useEffect(() => {
    const getCurrentFolder = () => {
      const url = `${API_URL}/folders/find_parent/${folderId}`;
      fetch(url, { method: 'GET'}).then(response => response.json())
      .then(data => {
        setCurrentFolder(data.folder);
        setCurrentParentFolder(data.parentFolder);
      })
    }
    getCurrentFolder()
  }, [folderId])

  const handleCreateFolder = () => {
    setCreateVisible(true);
  }

  const handleCreateFolderSubmit = () => {
    setCreateVisible(false);
    reloadData();
  }

  const reloadData = () => {
    if (paginate.total % 10 === 1 && paginate.current === Math.floor(paginate.total / 10) + 1) {
      fetchData(searchText, paginate.current - 1, paginate.pageSize, sort.field, sort.order);
    } else {
      fetchData(searchText, paginate.current, paginate.pageSize, sort.field, sort.order);
    }
  }

  const handleCancelSubmitFolder = () => {
    setCreateVisible(false);
  }

  const redirectToFolder = (folderId, e) => {
    e.preventDefault();
    handleRedirectDetail({ key : folderId})
  }

  const isAllowCreateFolder = useMemo(() => {
    if(user.role !== 'admin' || currentParentFolder){
      return false;
    }
    return true;
  }, [user, currentParentFolder])

  const renderFolderBreadcrumb = useMemo(() => {
    if(!currentParentFolder && currentFolder) return <span>{currentFolder.name}</span>
    return (
      <>
        <a onClick={(e) => redirectToFolder(currentParentFolder._id, e)}>{currentParentFolder?.name}</a>
        &gt;
        <span>{currentFolder?.name}</span>
      </>
    )
  }, [currentParentFolder, currentFolder])

  const handleCancelDelete = () => {
    setDeleteVisible(false);
    setCurrentSelectingFolder({});
  }
  
  const handleConfirmDelete = () => {
    setDeleteVisible(false);
    setCurrentSelectingFolder({})
    reloadData();
  }

  const handleCancelEdit = () => {
    setEditVisible(false);
    setCurrentSelectingFolder({})
  } 
  
  const handleConfirmEdit = () => {
    setEditVisible(false);
    reloadData();
    setCurrentSelectingFolder({})
  }

  return (
    <div className="slide-content">
       <CreateFolder
          visible={createVisible}
          handleCancel={handleCancelSubmitFolder}
          handleConfirm={handleCreateFolderSubmit}
          parentFolder={folderId}
        />
         <DeleteFolder
          currentFolder={currentSelectingFolder}
          visible={deleteVisible}
          handleCancelDelete={handleCancelDelete}
          handleConfirmDelete={handleConfirmDelete}
        />
         <EditFolder
          currentFolder={currentSelectingFolder}
          visible={editVisible}
          handleCancelEdit={handleCancelEdit}
          handleConfirm={handleConfirmEdit}
        />
      <Row className="welcome">
        <p className="welcome-content welcome-content-left"><a onClick={handleRedirectHome} >フォルダ一覧</a> &gt;{renderFolderBreadcrumb}</p>
      </Row>
      <Row className="slide-search">
        <Button type="primary" className="add-slide-button" onClick={handleCreate}><Icon type="plus" /> スライド新規登録</Button>
        {
          isAllowCreateFolder && (
            <Button type="primary" className="add-slide-button ml-1" onClick={handleCreateFolder}>
                <Icon type="folder-add" style={{ fontSize: "18px" }} />フォルダ新規登録</Button>
          )
        }
        
        <Tooltip title="Search">
          <Search
            placeholder="スライド名で検索"
            onSearch={value => handleSearch(value)}
            className="search-button"
          />
        </Tooltip>
      </Row>
      <Row className="slide-table">
        <Table
          columns={columns}
          dataSource={data}
          onChange={handleChange}
          pagination={false}
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="スライドはまだありません。" /> }}
          loading={isLoading}
          showSorterTooltip={true}
        />
      </Row>
      <Row className="slide-paginate">
      </Row>
    </div>
  );
}

export default Slide;

import React from "react";
import {Modal, Form, message, Button} from "antd";
import { API_URL } from '../../config/api.js';
import Axios from "axios";

const DeleteFolder = Form.create()(
  class extends React.Component {
    state = {
      isLoading: false,
      folder: {
        name: "",
        id: ""
      },
      error: false,
    };
    
    componentDidMount() {
      message.config({
        top: 100,
        duration: 1.5
      });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
      if (this.props.currentFolder !== nextProps.currentFolder) {
        this.setState({
          folder: {
            name: nextProps.currentFolder.name,
            id: nextProps.currentFolder.key,
          }
        })
      }
    }

    hideModal = () => {
      this.props.handleCancelDelete();
    };
    
    handleConfirmDelete = async () => {
      try {
        const { folder } = this.state;
        const url = `${API_URL}/folders/${folder.id}`;
        Axios.delete(url)
        .then(res => {
          this.props.handleConfirmDelete();
        })
        .catch(err => {
          console.log(err)
        })

      } catch(err) {
        console.log(err)
      }
    };

    handleConfirmDeleteAll = async () => {
      try {
        const { folder } = this.state;
        const url = `${API_URL}/folders/all/${folder.id}`;
        Axios.delete(url)
        .then(res => {
          this.props.handleConfirmDelete();
        })
        .catch(err => {
          console.log(err)
        })

      } catch(err) {
        console.log(err)
      }
    };
    
    render() {

      return (
        <div>
          <Modal
            title="フォルダを削除"
            visible={this.props.visible}
            onCancel={this.hideModal}
            width="620px"
            className="modal-slide"
            footer={[
              <Button type="default" key="1" onClick={this.hideModal}>キャンセル</Button>,
              <Button type="primary" key="2" onClick={this.handleConfirmDelete}>ファイルを守る</Button>,
              <Button type="danger" key="3" onClick={this.handleConfirmDeleteAll}>ファイルを削除</Button>
            ]}
          >
            <p>このフォルダのすべてのファイルを削除してもよろしいですか</p>
          </Modal>
        </div>
      );
    }
  }
);

export default DeleteFolder;

export const Helper = {
  trimString: (str) => {
    var regExp = /^\s+/g
    return str.replace(regExp, '')
  },
  getHeightOfText: (value) => {
    const arrayText = value.split('\n');
    const oldDiv = document.querySelector(".calc-text");
    if(oldDiv) {
      oldDiv.remove();
    }
    const div = document.createElement('div')
    div.classList.add('calc-text')
    div.style.padding = '0px'
    div.style.display = "inline-block"
    arrayText.forEach(text => {
      const span = document.createElement('span')
      span.style.fontSize = "16.5px"
      span.style.fontFamily = "Arial"
      span.innerText = text
      span.style.lineHeight = "19px"
      span.style.display = "block"
      span.style.padding = '0px'
      span.style.margin = '0px'
      div.appendChild(span)
    })
    div.display = "none";
    document.body.appendChild(div)
    const modal = div.getBoundingClientRect()
    setTimeout(() => {
      const oldDiv = document.querySelector(".calc-text");
      if(oldDiv) {
        oldDiv.remove();
      }
    }, 0)
    return  { width: modal.width, height: modal.height }
  },

  getTextWidthHeight: (value, font) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;

    const arrayText = value.split('\n');
    const arrayTextLength = arrayText.map(item => item.length);
    const maxLength = Math.max(...arrayTextLength);
    const maxText = arrayText[arrayTextLength.findIndex(item => item === maxLength)];
    const textWidth = context.measureText(maxText).width;
    const textHeight = context.measureText('M').width * arrayText.length;

    return {
      width: Math.ceil(textWidth),
      height: Math.ceil(textHeight)
    };
  },
  randomFromTo: (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
}

import React from 'react'
import './FullScreen.scss'
const FullScreen = ({ children, isFullScreen = false, renderControl, renderAction, showControl = false }) => {
  return (
    <div className={`fullscreen__container ${isFullScreen ? 'full': ''}`}>
        {children}
        {
          showControl ? (
            <>
              <div className='top-control'>
              { typeof renderControl === 'function' && renderControl(isFullScreen)}
              </div>
              <div className='bottom-control'>
                { typeof renderAction === 'function' && renderAction(isFullScreen)}
              </div>
            </> 
          ): null
        }
        
    </div>
  )
}

export default FullScreen
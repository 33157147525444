export const BOX_TYPE = {
  box_cause: "ShapeBoxCause",
  box_care: "ShapeBoxCare",
}

export const BOX_COLORS = {
  'white': '#ffffff',
  'orange': '#ffc68e',
  'yellow': '#ffff8e',
  'green': '#8eff8e',
  'purple': '#8ec6ff',
  'red': '#ff8e8e',
};

export const LINE_WEIGHTS = Object.freeze([1,2,3,4]);

export const DOT_WEIGHTS = Object.freeze([0,1]);

import React from "react";
import {Modal, Form, message, Button } from "antd";
import arrowLine1 from '../../assets/arrow-line-1-direction.png';
import arrowLine2 from '../../assets/arrow-line-2-direction.png';
import arrowDot1 from '../../assets/arrow-dot-1-direction.png';
import arrowDot2 from '../../assets/arrow-dot-2-direction.png';

import './edit-arrow.css';

const EditArrow = Form.create()(
  class extends React.Component {
    state = {
      isLoading: false,
      arrow: {
        id_arrow: "",
        arrowStyle: '',
      },
      error: false,
    };

    hideModal = () => {
      this.props.handleCancelEdit();
    };

    handleDelete = () => {
      this.props.handleDeleteArrow();
    }

    handleChangeArrowStyle = (arrowStyle) => {
      this.setState({ arrowStyle });
    }

    handleSubmit = () => {
      this.props.handleSaveArrow(this.state.arrowStyle);
    }

    render() {
      return (
        <div>
          <Modal
            title="編集矢印"
            visible={this.props.visible}
            onCancel={this.hideModal}
            okText="保存"
            cancelText="キャンセル"
            width="320px"
            className="modal-slide"
            footer={[
              <Button type='danger' key="1" className="btn-delete" onClick={this.handleDelete}>削除</Button>,
              <Button key="2" onClick={this.hideModal}>キャンセル</Button>,
              <Button type="primary" key="3" onClick={this.handleSubmit} >
                保存
              </Button>
            ]}
          >
            <div className="arrow-selector">
              <Button className={"arrow-button " + (this.state.arrowStyle === 'line1'? 'active': '')} onClick={this.handleChangeArrowStyle.bind(this, 'line1')}>
                <img src={arrowLine1} alt="arrow-line-1-direction" />
                </Button>
              <Button className={"arrow-button " + (this.state.arrowStyle === 'line2'? 'active': '')} onClick={this.handleChangeArrowStyle.bind(this, 'line2')}>
                <img src={arrowLine2} alt="arrow-line-2-direction" />
              </Button>
              <Button className={"arrow-button " + (this.state.arrowStyle === 'dot1'? 'active': '')} onClick={this.handleChangeArrowStyle.bind(this, 'dot1')}>
                <img src={arrowDot1} alt="arrow-dot-1-direction" />
              </Button>
              <Button className={"arrow-button " + (this.state.arrowStyle === 'dot2'? 'active': '')} onClick={this.handleChangeArrowStyle.bind(this, 'dot2')}>
                <img src={arrowDot2} alt="arrow-dot-2-direction" />
              </Button>
            </div>
          </Modal>
        </div>
      );
    }
  }
);

export default EditArrow;

import React from "react";
import {Modal, Form, Input, message, Button, Icon} from "antd";
import {Helper} from "../../utils/Helper";
import { BOX_TYPE } from "../../const/graph-value";

const FormItem = Form.Item;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3 }
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 18}
  }
};

let errorMsg = '';
let descErrorMsg = '';
const loadingIcon = <Icon type='loading' />

const EditShape = Form.create()(
  class extends React.Component {
    state = {
      // shapeDesc: "",
      isLoading: false,
      shape: {
        title: "",
        desc: "",
        type: BOX_TYPE.box_care,
      },
      error: false,
      requireDesc: false,
      isBtnSaveDisabled: false,
    };

    componentDidMount() {
      message.config({
        top: 100,
        duration: 1.5
      });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
      if (this.props.currentShape !== nextProps.currentShape) {
        let { requireDesc } = this.state;
        requireDesc = nextProps.currentShape.type === BOX_TYPE.box_care? true: false;
        this.setState({
          shape: {
            type: nextProps.currentShape.type,
            title: nextProps.currentShape.title,
            desc: nextProps.currentShape.desc,
            id_shape: nextProps.currentShape.id_shape,
            max_order: nextProps.currentShape.max_order,
            id: nextProps.currentShape.id,
          },
          requireDesc
        })
      }
    }

    onFieldChange(field, e) {
      const shape = this.state.shape;
      const newShape = { ...shape };
      let isError = false;

      if (field === 'shapeTitle') {
        newShape['title'] = Helper.trimString(e.target.value)
      } else if (field === 'shapeDesc') {
        newShape['desc'] = Helper.trimString(e.target.value)
      }
      const { title, desc } = newShape;
      if (title.length === 0) {
        isError = true;
        errorMsg = '概要を入力してください。';
      } else {
        isError = false;
        errorMsg = ''
      }
      if (desc.length === 0 && this.state.requireDesc) {
        isError = true;
        descErrorMsg = '入力してください。';
      } else {
        descErrorMsg = '';
      }
      this.setState({
        shape: newShape,
        error: isError,
        isBtnSaveDisabled: isError,
      });
    }

    hideModal = () => {
      this.props.handleCancelEdit();
    };

    handleSubmit = () => {
      const id_shape = this.state.shape.id_shape !== '' ? this.state.shape.id_shape : this.state.shape.max_order;
      const { title: shapeTitle, desc: shapeDesc, type } = this.state.shape;
      const isAddOrder = type === BOX_TYPE.box_care? true: false;
      const shapeTitleDisplay = isAddOrder ? `#${id_shape}. ${shapeTitle}` : shapeTitle;
      const shapeDescDisplay = isAddOrder ? `#${id_shape}. ${shapeDesc}` : shapeDesc;
      this.setState({
        isLoading: true,
        isBtnSaveDisabled: true,
      });

      setTimeout(() => {
        this.setState({
          isLoading: false,
          isBtnSaveDisabled: false,
        });
        this.props.handleSaveShape({
          type,
          shapeTitle,
          shapeDesc,
          shapeTitleDisplay,
          shapeDescDisplay,
          id_shape
        });
      }, 200);
    };

    handleDelete = () => {
      const id_shape = this.state.shape.id_shape !== '' ? this.state.shape.id_shape : '';
      this.props.handleDeleteShape({
        id_shape,
      });
    }
    
    render() {
      const { shape, error, isLoading } = this.state;

      return (
        <div>
          <Modal
            title="問題の修正"
            visible={this.props.visible}
            onCancel={this.hideModal}
            okText="保存"
            cancelText="キャンセル"
            width="620px"
            className="modal-slide"
            footer={[
              <Button type='danger' key="1" className="btn-delete" onClick={this.handleDelete}>削除</Button>,
              <Button key="2" onClick={this.hideModal}>キャンセル</Button>,
              <Button type="primary" key="3" onClick={this.handleSubmit} disabled={this.state.isBtnSaveDisabled}>
                {isLoading ? loadingIcon : ""} 保存
              </Button>
            ]}
          >
            <Form>
              <FormItem
                label="概要"
                required
                layout="horizontal"
                {...formItemLayout}
                className={errorMsg ? 'has-error' : ''}
              >
                <TextArea
                  value={shape.title}
                  rows={4}
                  onChange={e => this.onFieldChange('shapeTitle', e)}
                  name="shapeTitle"
                  id='edit'
                />
                {error && errorMsg && <p className='ant-form-explain'>{errorMsg}</p>}
              </FormItem>
              <FormItem
                label={this.state.shape.type === BOX_TYPE.box_care ? '問題' : 'メモ'}
                required={this.state.requireDesc}
                layout="horizontal"
                {...formItemLayout}
                className={descErrorMsg ? 'has-error' : ''}
              >
                <TextArea
                  rows={4}
                  value={shape && shape.desc}
                  onChange={e => this.onFieldChange('shapeDesc', e)}
                  name="shapeDesc"
                />
                {error && descErrorMsg && <p className='ant-form-explain'>{descErrorMsg}</p>}
              </FormItem>
            </Form>
          </Modal>
        </div>
      );
    }
  }
);

export default EditShape;

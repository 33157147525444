import React, {useState, useEffect, useRef} from 'react';
import {Layout, Col, Row, Card, Button, Input, Collapse, Icon} from "antd";
import Axios from "axios";
import { useHistory, Prompt, useParams} from 'react-router-dom'
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import MxGraphGridAreaEditor from '../../../components/MxGraphGridEditor';
import FreeText from './FreeText';
import Guess from './Guess';
import Header from "../../Header/index";
import { API_URL } from "../../../config/api";
import { Helper } from "../../../utils/Helper";
import '../SlideDetail.scss'
import useWindowDimensions from "../../../components/WindowDimension";
import { Auth } from "aws-amplify";
import FullScreen from '../../../components/FullScreen/FullScreen';
const iconMinus = <Icon type='minus' />
const iconExpand = <Icon type='arrows-alt' />
let workingSlideDefault = JSON.stringify({ graph: [] })
let errorMsg = ''
let isEditable = true
let isLoading = false

function SlideForm(props) {
  let [slide, setSlide] = useState(null);
  let [guess, setGuess] = useState([]);
  const [selected, setSelected] = useState();
  let [cellId, setCellId] = useState();
  const history = useHistory();
  let  folderId  = useParams().folderId;
  const [collapse1, setCollapse1] = useState(false)
  const [collapse2, setCollapse2] = useState(false)
  const [error, setError] = useState(false)
  const {windowWidth, windowHeight}  = useWindowDimensions()
  const windowDimension = {width: windowWidth, height: windowHeight}
  let isBtnSaveDisabled = useRef(true)
  let [labelPosition, setLabelPosition] = useState(true)
  const [isEdited, setIsEdited] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const graphRef = useRef();

  useEffect(() => {
    onbeforeunload = e => "Don't leave"

    return () => {
      onbeforeunload = null
    }
  }, []);

  const onFieldChange = (e) => {
    setIsEdited(true);
    const slideTitle = Helper.trimString(e.target.value)
    const len = slideTitle.length
    if (len === 0) {
      isBtnSaveDisabled.current = true
      setError(true)
      errorMsg = 'スライド名を入力してください。'
    } else if (len > 30) {
      isBtnSaveDisabled.current = true
      setError(true)
      errorMsg = 'スライド名は30文字以内で入力してください。'
    } else {
      isBtnSaveDisabled.current = false
      setError(false)
    }
    setSlide({
      ...slide,
      title: slideTitle
    })
  }

  const handleViewJson = (graphValue) => {
    setIsEdited(true);
    setSlide({
      ...slide,
      working_slide: graphValue
    })
  }

  const handleGetFreeText = (value) => {
    setIsEdited(true);
    setSlide({
      ...slide,
      free_text: value
    })
  }

  const handleSubmit = () => {
    setIsEdited(false);
    try {
      if (slide.title !== '') {
        var data = {
          ...slide,
          folder_id: folderId
        };
        if( slide.working_slide) {
          var graphCells = JSON.parse(slide.working_slide);
          var graphElement = graphCells.graph;
          var indexInit = 2;
          var originIndex = [];
          for (let index in graphElement) {
            var cell = graphElement[index];
            var indexUserId = cell.id;
            if (indexUserId !== indexInit) {
              cell.id = indexInit;
              originIndex[indexInit] = indexUserId;
              if(cell.value === "Edge" || cell.value === "EdgeD" || cell.value === "EdgeV" || cell.value === "EdgeH") {
                var sourceId = originIndex.indexOf(cell.source);
                var targetId = originIndex.indexOf(cell.target);
                if( sourceId !== -1) {
                  cell.source = sourceId;
                }
                if( targetId !== -1) {
                  cell.target = targetId;
                }
              }
            }
            indexInit ++;
          }
          graphElement.splice(indexInit- 3, 1);
          graphCells.graph = graphElement;
          var jsonGraph = JSON.stringify(graphCells);
          data = {
            ...data,
            working_slide: jsonGraph
          };
        }
        Axios.post(`${API_URL}/slides/create`, {...data})
          .then(res => {
            history.push(`/folders/detail/${folderId}`);
          })
          .catch(err => {
            console.log(err)
          })

        isBtnSaveDisabled.current = true
      }
    } catch(err) {
      console.log(err)
    }
  }

  const createSlide = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const res = await Axios.get(`${API_URL}/slides/create`)
      if (res && res.data && res.data.slide) {
        const slideData = res.data.slide
        setSlide({
          ...slideData,
          created_by: user.username,
          created_by_username: user.attributes.nickname,
          working_slide: slideData.working_slide,
          email: user.attributes.email,
        })
      }
    } catch (err) {
      if (error === 'not authenticated') {
        localStorage.setItem('token', '');
        history.push('/login');
      }
    }
  }

  useEffect( () => {
    createSlide()
  }, [])

  const handleCollapse = key => {
    if (key === 'key-1') {
      if (collapse1) {
        setCollapse1(false)
      } else {
        setCollapse1(true)
      }
    } else if (key === 'key-2') {
      if (collapse2) {
        setCollapse2(false)
      } else {
        setCollapse2(true)
      }
    }
  }

  const getArrayGuess = (guess) => {
    setGuess(guess);
  }

  const getMaxOrder = (value) => {
    setSlide({
      ...slide,
      max_order: value
    });
  }

  const handleClickWorkingSlide = () => {
    setSelected(0);
  }

  useEffect(() => {
    handleChooseShape();
  }, [cellId]);

  const handleChooseShape = (guessId) => {
    setCellId(guessId);
    setSelected(guessId);
  }

  const handleUpdateShape = (shape, newIndex) => {
    graphRef.current.updateShape(shape, newIndex);
  }

  useEffect(() => {
    if (collapse1 && collapse2) {
      setLabelPosition(false)
    } else {
      setLabelPosition(true)
    }
  }, [collapse1, collapse2])

  return (
    <>
      <div className='slide-detail'>
        <div className="header">
          <Header />
        </div>
        <div className='slide-container'>
          <Layout className=''>
            <Card className='card-container card-header'>
              <Row type='flex' justify='space-between'>
                <Row type='flex' className='slide-info'>
                  <div className={error ? 'has-error' : ''}>
                    <label className="ant-form-item-required">スライド名</label>
                    <Input
                      className='slide-name'
                      placeholder='スライド名'
                      name='slide_title'
                      required
                      value={ slide ? slide.title: ''}
                      onChange={(e) => onFieldChange(e)}
                    />
                    {error && errorMsg && <p className='ant-form-explain'>{errorMsg}</p>}
                  </div>
                  <div>
                    <p className='slide-creator'>記入者： <span>{slide ? slide.created_by_username : ''}</span></p>
                    <p className='slide-date'>最終編集: <span></span></p>
                  </div>
                </Row>
                <Col>
                  <Button type='primary'
                          className='btn-save'
                          disabled={isBtnSaveDisabled.current}
                          onClick={handleSubmit}
                  >保存</Button>
                </Col>
              </Row>
            </Card>
            <FullScreen isFullScreen={isFullScreen}>
            <Layout className='section-main'>
              <div className={"graph-side " + (labelPosition ? "" : "title-collapse")}>
                <MxGraphGridAreaEditor
                  ref={graphRef}
                  isFullScreen={isFullScreen}
                  toggleFullScreen={() => setIsFullScreen(!isFullScreen)}
                  handleViewJson={handleViewJson}
                  workingSlide={slide ? slide.working_slide : workingSlideDefault }
                  guess={guess}
                  getArrayGuess={getArrayGuess}
                  maxOrder={slide && slide.max_order ? slide.max_order : 1 }
                  getMaxOrder={getMaxOrder}
                  cellId={cellId}
                  isGraphEditable={isEditable}
                  windowDimension={windowDimension}
                  handleClickWorkingSlide={handleClickWorkingSlide}
                  labelPosition={labelPosition}
                  error={error}
                  loading={isLoading}
                />
              </div>
              <div className={"title-side " + (labelPosition ? "" : "title-collapse")}>
                <Collapse
                  defaultActiveKey={['key-1', 'key-2']}
                  className='section-side'
                >
                  <CollapsePanel key='key-1'
                                header={(
                                  <div className='panel-title' onClick={() => handleCollapse('key-1')}>要約</div>
                                )}
                                showArrow={false}
                                extra={(
                                  <Button onClick={() => handleCollapse('key-1')}>{ collapse1 ? iconExpand : iconMinus }</Button>
                                )}
                  >
                    <FreeText
                      handleGetFreeText={handleGetFreeText}
                      freeText={slide && slide.free_text ? slide.free_text: ''}
                      windowDimension={windowDimension}
                    />
                  </CollapsePanel>
                  <CollapsePanel key='key-2'
                                header={(
                                  <div className='panel-title' onClick={() => handleCollapse('key-2')}>問題リスト</div>
                                )}
                                showArrow={false}
                                extra={(
                                  <Button onClick={() => handleCollapse('key-2')}>{ collapse2 ? iconExpand : iconMinus }</Button>
                                )}
                  >
                    <Guess guess={guess}
                          handleChooseShape={handleChooseShape}
                          handleUpdateShape={handleUpdateShape}
                          windowDimension={windowDimension}
                          selected={selected}
                    />
                  </CollapsePanel>
                </Collapse>
                </div>
            </Layout>
            </FullScreen>
          </Layout>
          <Prompt
            when={isEdited}
            message='行った変更が保存されていませんがこのページを離れますか'
          />
        </div>
      </div>
    </>
  );
}

export default SlideForm;
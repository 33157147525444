import React from 'react';
import "antd/dist/antd.css";
import Amplify from "aws-amplify";
import awsconfig from "../../aws-exports";
import Header from '../Header';
import Folder from '../../components/Folder';

Amplify.configure(awsconfig);

function Home() {
  return (
    <div>
      <div className="header">
        <Header />
      </div>
      <main>
        <Folder />
      </main>
    </div>
  );
}

export default Home;